<footer class="footer-area mt-5">
    <div class="container mt-5">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <img src="assets/img/white.png" class="img-fluid" alt="logo">
                    </div>
                    <p class="mt-3">Brainex World: Empowering futures through free IT education and software development.</p>
                    <div class="footer-link">
                        <a routerLink="/">
                            <img src="assets/img/sass-landing/get-it-on-play-store-button-and-apple-app-store-icon-vector.jpg" alt="google image">
                        </a>
                        <a routerLink="/">
                            <img src="assets/img/sass-landing/get-it-on-play-store-button-and-apple-app-store-icon-vectorsdf.jpg" alt="google image">
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>Company Info</h3>
                    <ul>
                        <li><a href="https://brainexw@gmail.com"><i class="flaticon-right"></i> brainexw@gmail.com</a></li>
                        <li><a href="https://wa.me/03436523451"><i class="flaticon-right"></i> +92 323 936 6163</a></li>
                        <li><a href="https://wa.me/03436523451"><i class="flaticon-right"></i> +92 343 652 3451</a></li>
                        <li><a href="#"><i class="flaticon-right"></i> Office 101 Eman Plaza Mandian Abbottabad KPK Pakistan</a> </li>
                        <li><a href="#"><i class="flaticon-right"></i> SETMIND</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>Quick Links</h3>
                    <ul>
                        <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Home</span></li>
                        <li><span (click)="onClick('home')"><i class="flaticon-right"></i> About us</span></li>
                        <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Services</span></li>
                        <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Internship</span></li>
                        <li><span (click)="onClick('home')"><i class="flaticon-right"></i> Contact</span></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-widget pl-90">
                    <h3>Social Pages</h3>
                    <ul>
                        <li><a href="https://web.facebook.com/people/Brainex-world/100067619874325/" target="_blank"><i class="flaticon-right"></i> Facebook</a></li>
                        <li><a href="https://wa.me/03436523451" target="_blank"><i class="flaticon-right"></i> WhatsApp</a></li>
                        <li><a href="https://www.linkedin.com/company/brainex-world/" target="_blank"><i class="flaticon-right"></i> Linkedin</a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-right"></i> Twitter</a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-right"></i> Google</a> </li>
                    </ul>
                    <p class="mt-4">© Brainex World Pvt Ltd <a href="https://brainexworld.com/" target="_blank"></a></p>
                </div>
                
            </div>
        </div>
    </div>
    <!-- <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-4 lh-1">
                    <img src="https://images.unsplash.com/photo-1611162618071-b39a2ec055fb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1674&q=80" alt="visa card" width="50">
                    <img src="https://images.unsplash.com/photo-1614680376408-81e91ffe3db7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1674&q=80" width="50" alt="american-express">
                    <img src="https://images.unsplash.com/photo-1611944212129-29977ae1398c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8bGlua2VkaW58ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60" width="50" alt="discover">
                    <img src="https://images.unsplash.com/photo-1611605698335-8b1569810432?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1674&q=80" width="50" alt="master card">
                </div>
                <div class="col-lg-6 col-md-8 lh-1">
                    
                </div>
            </div>
        </div>
    </div> -->
</footer>