<div id="home" class="main-banner banner-style-three banner-bg-video ">
    <video autoplay muted loop>
        <source src="../../../../../assets/img/videos/product.mp4" type="video/mp4">
    </video>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-text">
                            <h1>Transforming Dreams into Reality: SetMind and Zentix VR Showcase</h1>
                            <p>
                                Embark on a journey of innovation and immersion as SetMind and Zentix VR come together,
                                transforming dreams into tangible realities through cutting-edge technology and
                                visionary design.
                            </p>
                            <div class="banner-btn">
                                <a routerLink="/agency-landing-one" class="active">Get Started</a>
                                <a routerLink="/agency-landing-one">Contact Us</a>
                            </div>
                        </div>
                    </div>
                    <div class="banner-img-three">
                        <img src="/assets/img/product.png" class="img-fluid" alt="consultant image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>