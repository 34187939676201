<div class="container-fluid main-bg">
          <div class="content">
                    <h2>Graphic UI/UX Desiging</h2>
                    <p class="sub-heading">
                              Home <i class="fa-solid fa-angles-right"></i> Services <i
                                        class="fa-solid fa-angles-right"></i> Graphic UI/UX Desiging
                    </p>
                    <a href="https://wa.me/03436523451" class="btn">
                              Let's Start
                    </a>
          </div>
</div>

<div class="container">
          <div class="row">
                    <div class="col-lg-4 col-md-4 co-sm-12 mt-5">
                              <div id="left-side">
                                        <button class="btn form-control p-3 mt-3 active side-btn">Visual
                                                  Storytelling</button>
                                        <button class="btn form-control p-3 mt-3 side-btn">User-Centered Design</button>
                                        <button class="btn form-control p-3 mt-3 side-btn">Seamless
                                                  Interactions</button>
                                        <button class="btn form-control p-3 mt-3 side-btn">Brand Consistency</button>
                                        <div class="left-img mt-4">
                                                  <h6>Contact us now</h6>
                                                  <h3 class="mt-5">If need help!</h3>
                                                  <h2 class="mt-5">+92 3436523451</h2>
                                                  <p class="mt-5">or go to contact from</p>
                                                  <h4 class="mt-5"><em class="fw-bold">Company Email:</em>&nbsp;&nbsp;
                                                            brainexw@gmail.com</h4>
                                                  <h4 class="mt-5"><em class="fw-bold">HR Email:</em>&nbsp;&nbsp;
                                                            brainexworldhr@gmail.com</h4>
                                        </div>
                                        <div class="second-left-img mt-4">

                                        </div>
                              </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-12 mt-5">
                              <div id="right-side">
                                        <div class="righ-img mt-3">
                                                  <img src="../../../../assets/img/service/"
                                                            class="img-fluid" alt="">
                                        </div>
                                        <h2 class="right-title mt-4">Graphic Designing UI/UX: Crafting Visual
                                                  Experiences That Resonate</h2>
                                        <p>
                                                  Welcome to Brainex World's Graphic Designing UI/UX services, where
                                                  creativity meets functionality. Our team of skilled designers
                                                  specializes in creating visually appealing graphics and intuitive user
                                                  interfaces that elevate user experiences. From stunning visuals to
                                                  seamless interactions, we bring your brand to life and ensure every
                                                  touchpoint leaves a lasting impression.
                                        </p>
                                        <h2 class="righ-sub-heading">Our Approach:</h2>
                                        <p>
                                                  At Brainex World, we understand the power of design in shaping
                                                  perceptions and driving engagement. Our approach blends aesthetics
                                                  with user-centric functionality to create designs that captivate and
                                                  convert. Here's how we make design come alive:
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">1:</strong>Visual Storytelling
                                        </h3>
                                        <p class="strong-para">
                                                  Our designs tell a story that resonates with your audience, creating
                                                  an emotional connection that lingers.
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">2:</strong>User-Centered
                                                  Design</h3>
                                        <p class="strong-para">
                                                  We prioritize user experience, ensuring that every element is designed
                                                  with the user's needs and preferences in mind.
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">3:</strong>Seamless
                                                  Interactions</h3>
                                        <p class="strong-para">
                                                  Our UI/UX designers craft interfaces that are not only visually
                                                  appealing but also intuitive and easy to navigate.
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">4:</strong>Brand Consistency
                                        </h3>
                                        <p class="strong-para">
                                                  Our designs are an extension of your brand identity. We maintain
                                                  consistency across all touchpoints, reinforcing your brand message.
                                        </p>

                              </div>
                    </div>
          </div>
</div>
<!--Our Graphic Designing UI/UX Services-->
<div class="container-fluid service-container mt-5">
          <h2 class="text-center title">Our Graphic Designing UI/UX Services</h2>
          <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-12"></div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>User Interface (UI) Design</h6>
                                        <p class="mt-4">
                                                  Craft visually appealing and user-friendly interfaces that guide users
                                                  through your digital platforms.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>User Interface (UI) Design</h6>
                                        <p class="mt-4">
                                                  Enhance user satisfaction by improving the usability, accessibility,
                                                  and overall interaction with your digital products.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>Visual Identity</h6>
                                        <p class="mt-4">
                                                  Create a strong visual identity that resonates with your brand values
                                                  and helps you stand out in a competitive landscape.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>Logo and Branding</h6>
                                        <p class="mt-4">
                                                  Develop memorable logos and branding materials that leave a lasting
                                                  impression and create brand recognition.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>Print Design</h6>
                                        <p class="mt-4">
                                                  Extend your brand's reach beyond digital with professionally designed
                                                  print materials that communicate your message effectively.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-12"></div>

          </div>
</div>
<!-- WHY CHOOSE  -->
<div class="container-fluid mt-5 why-choose">
          <h2 class="text-center why-choose-title">Why Choose Brainex World <br> for Graphic Designing UI/UX?</h2>
          <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                              <img src="../../../../assets/img/service/graphic-lower.jpg"
                                        class="img-fluid why-choose-img" alt="">
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                              <ul class="mt-5">
                                        <li class="mt-4">
                                                  <strong>Creative Excellence:</strong>
                                                  <p>
                                                            Our designers blend artistic flair with strategic thinking,
                                                            ensuring designs that are both visually appealing and
                                                            purposeful.
                                                  </p>
                                        </li>
                                        <li class="mt-4">
                                                  <strong>User-Centric Focus:</strong>
                                                  <p>
                                                            We prioritize the user journey, designing interfaces that
                                                            are intuitive, user-friendly, and aligned with user
                                                            expectations.
                                                  </p>
                                        </li>
                                        <li class="mt-4">
                                                  <strong>Brand Alignment:</strong>
                                                  <p>
                                                            Our designs are an authentic representation of your brand's
                                                            identity, values, and objectives.
                                                  </p>
                                        </li>
                                        <li class="mt-4">
                                                  <strong>Proven Track Record:</strong>
                                                  <p>
                                                            With a portfolio of successful design projects, we showcase
                                                            our ability to create impactful designs that drive results.
                                                  </p>
                                        </li>
                              </ul>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12 mt-5">
                              <h3 class="fw-bold text-center mt-4">Elevate Your Design Game:</h3>
                              <p class="text-center">
                                        Are you ready to create visual experiences that leave a lasting impact? Brainex
                                        World is your partner in transforming design into a strategic advantage. Contact
                                        us today to discuss your graphic designing UI/UX project and embark on a journey
                                        of design excellence.
                              </p>
                    </div>
          </div>
</div>
<!-- CONTACT INFO  -->
<section class="container-fluid mt-5 contact-info">
          <h2>Contact Info</h2>

          <div class="contact-info">
                    <p><strong>Contact No:</strong> +92 3436523451</p>
                    <p><strong>Email:</strong> brainexw@gmail.com</p>
                    <p><strong>HR Email:</strong> brainexworldhr@gmail.com</p>
                    <p><strong>Address:</strong> Office 101, Emaan Palaza, Opposite Daewo Terminal Mandian Abbottabad
                              KPK Pakistan</p>
          </div>

          <div class="social-icons">
                    <a href="https://web.facebook.com/people/Brainex-world/100067619874325/" target="_blank"><i
                                        class="fa-brands fa-facebook"></i></a>
                    <a href="https://wa.me/03436523451" target="_blank"><i class="fa-brands fa-square-whatsapp"></i></a>
                    <a href="https://www.linkedin.com/company/brainex-world/" target="_blank"><i
                                        class="fa-brands fa-linkedin"></i></a>
                    <a href="#" target="_blank"><i class="fa-brands fa-google-plus"></i></a>

          </div>
</section>
<br><br>