<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
        <a class="navbar-brand" routerLink="/" >
            <img src="assets/img/logoo.jpg"  alt="logo" width="150"  class="logo">
        </a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse text-center" id="navbarSupportedContent">
            <ul class="navbar-nav m-auto">
                <li class="nav-item"><span class="nav-link" routerLink="/">Home</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/aboutus">About Us</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/ourservices">Our Services</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/ourproducts">Our Products</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/setmind#InternShip">Internships</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('testimonials')">Contact Us</span></li>
            </ul>
            <ul class="navbar-nav ms-auto for-responsive">
                <li class="nav-item"><span class="nav-link" routerLink="/">Home</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/aboutus">About Us</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/ourservices">Our Services</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/setmind#InternShip">Our Products</span></li>
                <li class="nav-item"><span class="nav-link" routerLink="/setmind#InternShip">Internships</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('testimonials')">Contact Us</span></li>
            </ul>
            <div class="navbar-btn">
                <a routerLink="/login">Login</a>
            </div>
            <div class="navbar-btn">
                <a routerLink="/register">Register</a>
            </div>
        </div>
    </div>
</nav>