<div id="services" class="service-style-three pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Services I Provide for Clients</h2>
            <p>Empowering clients through a spectrum of digital solutions. From web and app development to Python
                programming and 3D modeling, discover tailored services that bring your visions to life.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="service-items wow fadeInUp" data-wow-duration="1s">
                    <i class="flaticon-ui"></i>
                    <h3>Web Development</h3>
                    <p>
                        Welcome to Brainex World's Web Development. We craft immersive digital experiences, turning
                        visions into captivating websites with precision and skill.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-items wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2">
                    <i class="flaticon-web-programming"></i>
                    <h3>App Development</h3>
                    <p>
                        Welcome to App Development at Brainex World. We turn ideas into powerful apps, redefining user
                        experiences with innovation and precision.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-items wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3">
                    <i class="flaticon-medical-app"></i>
                    <h3>3D Modeling and Architecture</h3>
                    <p>
                        Discover 3D Modeling at Brainex World. Elevate your concepts with our precision designs,
                        crafting immersive visual experiences that redefine creativity.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-items wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4">
                    <i class="flaticon-writing"></i>
                    <h3>AI Python Programming</h3>
                    <p>
                        Explore AI Python Programming at Brainex World. Unleash smart solutions with our expert coding
                        for a future of innovation.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-items active wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5">
                    <i class="flaticon-java"></i>
                    <h3>WordPress Customization</h3>
                    <p>
                        Explore WordPress Customization at Brainex World. Tailoring unique and responsive websites for a
                        standout online presence.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-items wow fadeInUp" data-wow-duration="1s" data-wow-delay=".6">
                    <i class="flaticon-video"></i>
                    <h3>Graphic Designing UI/UX</h3>
                    <p>
                        Discover Graphic Designing UI/UX at Brainex World. Elevate your brand with visually stunning and
                        user-friendly designs that captivate and engage audiences effortlessly.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>