<div id="services" class="service-style-four pb-70">
    <div class="container">
        <div class="section-title">
            <span>Internship Services</span>
            <h2>Internship Tracks</h2>
            <p>
                Choose from a variety of internship tracks based on your passion and skillset:
            </p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-computer"></i>
                    <h3>Web Development</h3>
                    <p>
                        Craft responsive and dynamic websites that leave a lasting impact.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-ui"></i>
                    <h3>App Development</h3>
                    <p>
                        Dive into mobile app creation and user experience optimization.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-web-programming"></i>
                    <h3>AI Python Programming</h3>
                    <p>
                        Explore the world of AI and machine learning with Python.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-clipboard"></i>
                    <h3>3D Modeling and Architecture</h3>
                    <p>
                        Create immersive 3D models and architectural designs.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-pie-chart"></i>
                    <h3>WordPress Customization</h3>
                    <p>Master the art of customizing and optimizing WordPress platforms.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="service-card">
                    <i class="flaticon-pie-chart"></i>
                    <h3>Graphic Designing UI/UX</h3>
                    <p>Design captivating visuals and user interfaces.</p>
                </div>
            </div>
        </div>
    </div>
</div>