<div id="home" class="main-banner banner-style-two banner-bg-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text">
                    <h1>Welcome to Brainex World Empowering Dreams Through Technology</h1>
                    <p>
                        Empower your business with Brainex World's IT solutions. From web development to 3D modeling, we drive growth. Contact us for success today!
                    </p>
                    <div class="banner-btn">
                        <div class="button">
                            <div class="button__text" (click)="ngxSmartModalService.getModal('popupOne').open()">Read More...</div>
                            
                            <div class="button__wrapper">
                              <div class="button__arrow"></div>
                              <div class="button__border-circle"></div>
                              <div class="button__mask-circle">
                                <div class="button__small-circle"></div>
                              </div>
                            </div>
                          </div>
                    </div>
                    <!-- <div class="mt-2 p-4 "  style="background-color: #a0c7edac;">
                        <div class="float-left">
                            <img src="https://o.remove.bg/downloads/86adb753-4e11-44f2-b59d-fe805ccef3bb/speaker-vector-png-2-removebg-preview.png" class="img-fluid" alt="" style="width: 20%;">
                        </div>
                    </div> -->
                    <br>
                    <!-- button css  -->

                    <!-- button End  -->
                </div>
            </div>
        </div>
    </div>
    <div class="banner-image-two">
        <img src="assets/img/service/home.png" alt="laptop" class="right-img">
        <img src="assets/img/shape/home-shape-three.png" alt="shape">
    </div>
    <div class="banner-shape">
        <img src="assets/img/shape/home-shape.png" alt="shape">
        <img src="assets/img/shape/home-shape-two.png" alt="shape">
        <img src="assets/img/shape/home-shape-four.png" alt="shape">
        <img src="assets/img/shape/home-shape-three.png" alt="shape">
    </div>
</div>
<div class="video-popup">
    <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal>
</div>