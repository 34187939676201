<div id="apps" class="our-apps pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="our-apps-img wow fadeInUp" data-wow-duration="1s">
                    <img src="https://images.unsplash.com/photo-1558301204-e3226482a77b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1730&q=80" alt="iphone">
                </div>
            </div>
            <div class="col-lg-7">
                <div class="our-apps-text">
                    <div class="section-title text-start">
                        <span> Empower Your Future</span>
                        <h2>CONNECT WITH OUR SETMIND</h2>
                    </div>
                    <p>
                        At SETMIND, our internship panel and learning institutes are dedicated to nurturing your
                        growth. We're not just about education; we're about transformation. Our programs are designed to
                        expand your horizons, develop your skills, and connect you with a world of opportunities. Join
                        us on a journey of knowledge and empowerment, where your potential knows no bounds.
                    </p>
                    <div class="our-apps-btn">
                        <a routerLink="/" class="active">
                            <i class="fa-brands fa-connectdevelop" style="color: #fff;"></i>
                            <h5>Let's Connect</h5>

                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="apps-shape">
        <img src="assets/img/shape/1.png" alt="shape">
        <img src="assets/img/shape/3.png" alt="shape">
        <img src="assets/img/shape/5.png" alt="shape">
        <img src="assets/img/shape/6.png" alt="shape">
        <img src="assets/img/map-two.png" alt="shape">
    </div>
</div>