<div class="how-use pt-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Values</h2>
            <p>
                At the heart of our ethos are Empowerment, Innovation, Collaboration, and Excellence, shaping a culture
                that unlocks potential, embraces change, thrives on teamwork, and consistently strives for the highest
                standards.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <div class="how-use-card how-card-one">
                            <span>1</span>
                            <i class="flaticon-coding"></i>
                            <h3>Empowerment</h3>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <p>We believe that knowledge is power. We empower individuals to realize their potential and
                            overcome barriers through learning and skill development.</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <p>Innovation drives progress. We constantly seek new ways to push boundaries, create solutions,
                            and redefine the possibilities in the IT landscape.</p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <div class="how-use-card how-card-two">
                            <span>2</span>
                            <i class="flaticon-settings"></i>
                            <h3>Innovation</h3>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <div class="how-use-card how-card-three">
                            <span>3</span>
                            <i class="flaticon-map"></i>
                            <h3>Collaboration</h3>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <p>
                            Collaboration is at the heart of our success. We foster an environment where teamwork,
                            communication, and shared goals lead to extraordinary results.
                        </p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <p>
                            Excellence is our standard. From our services to our internships, we strive for excellence
                            in every endeavor, delivering quality and value to our clients and interns.
                        </p>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 wow fadeInUp" data-wow-duration="1s">
                        <div class="how-use-card how-card-four">
                            <span>4</span>
                            <i class="flaticon-ticket"></i>
                            <h3>• Excellence</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 offset-lg-2 wow fadeInUp" data-wow-duration="1s">
                <div class="how-use-slider">
                    <owl-carousel-o [options]="howToUseSlider">
                        <ng-template carouselSlide>
                            <div class="how-use-img">
                                <img src="https://images.pexels.com/photos/3194519/pexels-photo-3194519.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="iphone">
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="how-use-img">
                                <img src="https://images.pexels.com/photos/2007647/pexels-photo-2007647.jpeg?auto=compress&cs=tinysrgb&w=600" alt="iphone">
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="how-use-img">
                                <img src="https://images.pexels.com/photos/259006/pexels-photo-259006.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="iphone">
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>