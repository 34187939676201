<div class="counter-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-9 offset-lg-1">
                <div class="row">
                    <div class="col-lg-2 col-6 col-md-3">
                        <div class="counter-text">
                            <i class="flaticon-value"></i>
                            <h2><span class="counter">11</span>+</h2>
                            <p>Clients</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6 col-md-3">
                        <div class="counter-text">
                            <i class="flaticon-rating"></i>
                            <h2><span class="counter">450</span>+</h2>
                            <p>Happy Clients</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6 col-md-3">
                        <div class="counter-text">
                            <i class="flaticon-clipboard"></i>
                            <h2><span class="counter">5</span>+</h2>
                            <p>Pending Task</p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-6 col-md-3">
                        <div class="counter-text">
                            <i class="flaticon-rocket"></i>
                            <h2><span class="counter">117</span>+</h2>
                            <p>Prjects Done</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="counter-img wow" data-wow-duration="1s">
        <img src="assets/img/sass-landing/main.png" alt="laptop" class="mb-2">
        <img src="assets/img/sass-landing/service-image.png" alt="laptop">
    </div>
</div>