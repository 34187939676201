<div id="services" class="product-row service-style-four pb-70">
    <div class="container ">
        <div class="section-title">
            <span></span>
            <h2 class="text-white pt-5">Our Tech Marvels</h2>
            <p class="text-light">
                Discover the future of learning and immersive experiences with our flagship products, SetMind and Zentix
                VR, crafted at the intersection of technology and education to empower and innovate.
            </p>
        </div>
        <div class="row ">
            <div class="col-lg-6 col-md-6 col-sm-12 p-5">
                <div class="lower-section-title p-4 rouded shadow">
                    <h2><b class="text-white" style="text-shadow: 0px 0px 10px #000;">SETMIND:</b></h2>
                    <h3 class="text-white">Unleashing Potential, Fostering Innovation</h3>
                    <p class="text-light">
                        Discover SetMind, Brainex World's flagship product dedicated to empowering students with
                        essential tech skills. From coding fundamentals to advanced digital design, SetMind offers a
                        comprehensive course catalog, fostering creativity through hands-on projects. Gain a competitive
                        edge with practical learning and step into a future filled with exciting career opportunities.
                    </p>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <button class="btn mt-3 details-btn">View Details</button>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="log-div p-1">
                                <img src="/assets/img/logos/setmind.png" class="img-fluid logos" alt="">
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 p-5">
                <div class="lower-section-title p-4 rouded shadow">
                    <h2><b class="text-white" style="text-shadow: 0px 0px 10px #000;">Zentix VR:</b></h2>
                    <h3 class="text-white">Revolutionizing Reality in Pakistan</h3>
                    <p class="text-light">
                        Introducing Zentix VR—an innovation transforming 3D creations into captivating virtual reality
                        experiences. Immerse yourself in dream-driven design, where your vision becomes reality. Zentix
                        VR stands out with realistic visualizations, interactive walkthroughs, and a commitment to
                        client success through education and long-term partnerships. Join us on a journey to redefine
                        immersive experiences in Pakistan.
                    </p>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <button class="btn mt-3 details-btn">View Details</button>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <img src="/assets/img/logos/zentix.jpg" class="img-fluid log-div" alt="">
                        </div>
                    </div>
                    
                </div>
            </div>

        </div>
    </div>
</div>