<div class="work-process pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="text-light">Seamless Application Process: Your Gateway to Opportunity</span>
            <h2 class="fw-bold text-white">How to Apply</h2>
            <p class="text-light">
                Applying for our internship is easy and straightforward. Take the first step towards your future in
                tech.
            </p>
        </div>
        <div class="row">
            <div class="col-lg-1 col-sm-6"></div>
            <div class="col-lg-2 col-sm-6 offset-lg-1">
                <div class="process-card">
                    <h2>1</h2>
                    <h3>Check Eligibility</h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-6">
                <div class="process-card card-two">
                    <h2>2</h2>
                    <h3>Submit Your Application</h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-6">
                <div class="process-card card-three">
                    <h2>3</h2>
                    <h3>Review and Selection</h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-6">
                <div class="process-card card-four">
                    <h2>4</h2>
                    <h3>Embark on Your Journey</h3>
                </div>
            </div>
            <div class="col-lg-1 col-sm-6"></div>

        </div>
    </div>
</div>