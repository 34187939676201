import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { mergeMap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    private apiUrl = "assets/users.json"; // Assuming users.json is in the src/assets folder

    constructor(private http: HttpClient) {}

    // Simulate login by checking credentials against JSON data
    login(email: string, password: string): Observable<boolean> {
        return this.http.get<User[]>(this.apiUrl).pipe(
            map((users) => {
                const user = users.find(
                    (u) => u.email === email && u.password === password
                );
                if (user) {
                    localStorage.setItem("currentUser", JSON.stringify(user));
                    return true; // Login successful
                } else {
                    return false; // Login failed
                }
            }),
            catchError(() => of(false))
        );
    }

    // Simulate user registration by adding a new user to the JSON data
    // auth.service.ts

    register(user: User): Observable<boolean> {
        return this.http.get<User[]>(this.apiUrl).pipe(
            mergeMap((users) => {
                const existingUser = users.find(
                    (u) =>
                        u.username === user.username || u.email === user.email
                );
                if (!existingUser) {
                    users.push(user);
                    // Update the JSON file with the new user data
                    // This would typically involve making an HTTP POST request to a server
                    // Here, we're using of() to simulate a successful registration
                    return of(true);
                } else {
                    return of(false); // User with the same username or email already exists
                }
            }),
            catchError(() => of(false))
        );
    }

    // Log out the user by removing the current user from local storage
    logout(): void {
        localStorage.removeItem("currentUser");
    }

    // Get the currently logged-in user from local storage
    getCurrentUser(): User | null {
        const currentUser = localStorage.getItem("currentUser");
        return currentUser ? JSON.parse(currentUser) : null;
    }
}

export interface User {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    username: string;
    password: string;
    // Add other user properties as needed
}
