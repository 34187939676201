<div class="container-fluid bg">
          <div class="row">
                    <div class="col-md-7">

                              <h2 class="info">
                                        Sign Up to Brainex World
                              </h2>
                              <p class="info-des">
                                        Welcome to Brainex World Secure Portal. Sign Up to access our cutting-edge
                                        software solutions, <br> immersive learning experiences, and exciting internship
                                        opportunities. Your gateway to innovation <br> awaits!
                              </p>

                              <div class="container-first mt-3" id="container">
                                        <div class="form-container sign-in-container">
                                                  <form action="#">
                                                            <!-- <h2>Welcome Back Brainex World User</h2> -->
                                                            <h1 class="mt-3 signin">Sign Up you Account</h1>

                                                            <!-- <span>or use your account</span> -->
                                                            <input type="text" placeholder="First Name" />
                                                            <input type="text" placeholder="Last Name" />
                                                            <input type="email" placeholder="Email" />
                                                            <input type="text" placeholder="Phone Number" />
                                                            <input type="password" placeholder="Password" />
                                                            <div class="d-flex">
                                                                      <button type="submit">Sign Up</button>
                                                                      <a href="#" class="text-white forget">Forgot your Email?</a>
                                                            </div>


                                                            <div class="social-container m-auto">
                                                                      <a href="#" class="social facebook"><i
                                                                                          class="fab fa-facebook-f"></i></a>
                                                                      <a href="#" class="social google"><i
                                                                                          class="fab fa-google-plus-g"></i></a>
                                                                      <a href="#" class="social linkedin"><i
                                                                                          class="fab fa-linkedin-in"></i></a>
                                                            </div>
                                                  </form>
                                        </div>
                              </div>
                    </div>
                    <div class="col-md-5 images">
                              <div class="hero-images">
                                        <div class="shape-1">
                                                  <img src="assets/img/sass-landing/hero-shape-4.png" alt="">
                                        </div>
                                        <!-- <div class="images">
                                                  <img src="assets/img/sass-landing/register.png"
                                                            class="why-choose-img" alt="">
                                        </div> -->
                              </div>
                    </div>
                    <!-- <div class="col-md-1"></div> -->
          </div>
</div>

<br>