<div class="container-fluid main-bg">
          <div class="content">
                    <h2>Web Development</h2>
                    <p class="sub-heading">
                              Home <i class="fa-solid fa-angles-right"></i> Services <i
                                        class="fa-solid fa-angles-right"></i> Web Development
                    </p>
                    <a href="https://wa.me/03436523451" class="btn">
                              Let's Start
                    </a>
          </div>
</div>

<div class="container">
          <div class="row">
                    <div class="col-lg-4 col-md-4 co-sm-12 mt-5">
                              <div id="left-side">
                                        <button class="btn form-control p-3 mt-3 active side-btn">Responsive
                                                  Development</button>
                                        <button class="btn form-control p-3 mt-3 side-btn">User-Centric Design</button>
                                        <button class="btn form-control p-3 mt-3 side-btn">Performance
                                                  Optimization</button>
                                        <button class="btn form-control p-3 mt-3 side-btn">Cutting-Edge
                                                  Technology</button>
                                        <div class="left-img mt-4">
                                                  <h6>Contact us now</h6>
                                                  <h3 class="mt-5">If need help!</h3>
                                                  <h2 class="mt-5">+92 3436523451</h2>
                                                  <p class="mt-5">or go to contact from</p>
                                                  <h4 class="mt-5"><em class="fw-bold">Company Email:</em>&nbsp;&nbsp;
                                                            brainexw@gmail.com</h4>
                                                  <h4 class="mt-5"><em class="fw-bold">HR Email:</em>&nbsp;&nbsp;
                                                            brainexworldhr@gmail.com</h4>
                                        </div>
                                        <div class="second-left-img mt-4">

                                        </div>
                              </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-12 mt-5">
                              <div id="right-side">
                                        <div class="righ-img mt-3">
                                                  <img src="../../../../assets/img/service/web-below.jpg"
                                                            class="img-fluid" alt="">
                                        </div>
                                        <h2 class="right-title mt-4">Web Development Crafting Digital Experiences</h2>
                                        <p>
                                                  Welcome to the Web Development services at Brainex World. We're not
                                                  just building websites; we're creating immersive and engaging digital
                                                  experiences that captivate audiences and drive results. Our team of
                                                  skilled developers, designers, and strategists collaborate to bring
                                                  your vision to life, ensuring every pixel and line of code is
                                                  thoughtfully crafted.
                                        </p>
                                        <h2 class="righ-sub-heading">Our Approach:</h2>
                                        <p>
                                                  At Brainex World, we believe that every website is a unique reflection
                                                  of your brand. Our approach combines creativity, innovation, and
                                                  technical expertise to deliver websites that stand out in today's
                                                  competitive digital landscape. From concept to deployment, we focus on
                                                  the following principles:
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">1:</strong> Responsive
                                                  Development</h3>
                                        <p class="strong-para">
                                                  In a mobile-first era, responsive design is crucial. Our websites
                                                  adapt to various screen sizes and devices, ensuring a consistent and
                                                  enjoyable experience for all users.
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">2:</strong>User-Centric Design
                                        </h3>
                                        <p class="strong-para">
                                                  We prioritize user experience, creating intuitive interfaces that
                                                  guide visitors seamlessly through your website. Our designs are not
                                                  just visually appealing, but also functional and user-friendly.
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">3:</strong>Performance
                                                  Optimization</h3>
                                        <p class="strong-para">
                                                  Slow websites can deter users and affect your search engine rankings.
                                                  We optimize every aspect of your website for speed, keeping your
                                                  visitors engaged and satisfied.
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">4:</strong>Cutting-Edge
                                                  Technology</h3>
                                        <p class="strong-para">
                                                  Our developers stay up-to-date with the latest technologies and
                                                  trends. We leverage tools like Node.Js, Angular, React, Laravel,
                                                  HTML5, CSS3, JavaScript, and more to create dynamic and interactive
                                                  web experiences.
                                        </p>

                              </div>
                    </div>
          </div>
</div>
<!-- Our Web Development Services: -->
<div class="container-fluid service-container mt-5">
          <h2 class="text-center title">Our Web Development Services</h2>
          <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-12"></div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>Static Websites</h6>
                                        <p class="mt-4">
                                                  Simple yet elegant websites that deliver your message effectively.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>Dynamic Websites</h6>
                                        <p class="mt-4">
                                                  Interactive websites with dynamic content and personalized user
                                                  experiences.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>E-Commerce</h6>
                                        <p class="mt-4">
                                                  Robust online stores that streamline the shopping experience and boost
                                                  sales.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>(CMS) Systems</h6>
                                        <p class="mt-4">
                                                  Empower yourself with easy-to-manage websites using platforms like
                                                  WordPress, Joomla, and more.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>Custom Web Applications</h6>
                                        <p class="mt-4">
                                                  Tailored solutions for unique business needs, enhancing efficiency and
                                                  productivity.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-12"></div>

          </div>
</div>
<!-- WHY CHOOSE  -->
<div class="container-fluid mt-5 why-choose">
          <h2 class="text-center why-choose-title">Why Choose Brainex World <br> for Web Development?</h2>
          <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                              <img src="../../../../assets/img/service/web-lower.jpg"
                                        class="img-fluid why-choose-img" alt="">
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                              <ul class="mt-5">
                                        <li class="mt-4">
                                                  <strong>Strategic Approach:</strong>
                                                  <p>
                                                            We take the time to understand your business goals and
                                                            target audience, ensuring your website aligns with your
                                                            objectives.
                                                  </p>
                                        </li>
                                        <li class="mt-4">
                                                  <strong>Collaborative Process:</strong>
                                                  <p>
                                                            Your input is invaluable. We work closely with you
                                                            throughout the development process to ensure your vision
                                                            comes to life.
                                                  </p>
                                        </li>
                                        <li class="mt-4">
                                                  <strong>Proven Expertise:</strong>
                                                  <p>
                                                            Our portfolio showcases a diverse range of successful web
                                                            projects that highlight our skills and commitment to
                                                            excellence.
                                                  </p>
                                        </li>
                                        <li class="mt-4">
                                                  <strong>Timely Delivery:</strong>
                                                  <p>
                                                            We understand the importance of deadlines. Our team strives
                                                            to deliver projects on time without compromising quality.
                                                  </p>
                                        </li>
                              </ul>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12 mt-5">
                              <h3 class="fw-bold text-center mt-4">Let's Build Your Digital Presence:</h3>
                              <p class="text-center">
                                        Ready to embark on a journey to enhance your online presence? Let Brainex World
                                        be your partner in crafting exceptional web experiences. Contact us today to
                                        discuss your project, and let's turn your vision into a stunning reality.
                              </p>
                    </div>
          </div>
</div>
<!-- CONTACT INFO  -->
<section class="container-fluid mt-5 contact-info">
          <h2>Contact Info</h2>

          <div class="contact-info">
                    <p><strong>Contact No:</strong> +92 3436523451</p>
                    <p><strong>Email:</strong> brainexw@gmail.com</p>
                    <p><strong>HR Email:</strong> brainexworldhr@gmail.com</p>
                    <p><strong>Address:</strong> Office 101, Emaan Palaza, Opposite Daewo Terminal Mandian Abbottabad
                              KPK Pakistan</p>
          </div>

          <div class="social-icons">
                    <a href="https://web.facebook.com/people/Brainex-world/100067619874325/" target="_blank"><i
                                        class="fa-brands fa-facebook"></i></a>
                    <a href="https://wa.me/03436523451" target="_blank"><i class="fa-brands fa-square-whatsapp"></i></a>
                    <a href="https://www.linkedin.com/company/brainex-world/" target="_blank"><i
                                        class="fa-brands fa-linkedin"></i></a>
                    <a href="#" target="_blank"><i class="fa-brands fa-google-plus"></i></a>

          </div>
</section>
<br><br>