import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SassLandingOneComponent } from "./components/pages/sass-landing-one/sass-landing-one.component";
import { AppLandingComponent } from "./components/pages/app-landing/app-landing.component";
import { SassLandingTwoComponent } from "./components/pages/sass-landing-two/sass-landing-two.component";
import { AgencyLandingOneComponent } from "./components/pages/agency-landing-one/agency-landing-one.component";
import { AgencyLandingTwoComponent } from "./components/pages/agency-landing-two/agency-landing-two.component";
import { PortfolioLandingComponent } from "./components/pages/portfolio-landing/portfolio-landing.component";

const routes: Routes = [
    { path: "", component: SassLandingOneComponent },
    { path: "ourservices", component: SassLandingTwoComponent },
    { path: "aboutus", component: AppLandingComponent },
    { path: "ourproducts", component: AgencyLandingOneComponent },
    { path: "setmind#InternShip", component: AgencyLandingTwoComponent },
    { path: "portfolio-landing", component: PortfolioLandingComponent },
    { path: "services", loadChildren: () =>import("./components/service-details/service-details.module").then( (m) => m.ServiceDetailsModule),},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
