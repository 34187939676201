<div id="about" class="why-choose why-choose-two pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span style="color: #2be0f7;">Why Choose Us?</span>
            <p class="text-white">
                Welcome to Brainex World - Empowering Dreams Through Technology.
            </p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="hero-images">
                <div class="shape-1">
                    <img src="assets/img/sass-landing/hero-shape-4.png"  alt="">
                </div>
                <div class="images">
                    <img src="assets/img/sass-landing/testi-5.png" class="why-choose-img" alt="">
                </div>
            </div>
                <!-- <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="assets/img/sass-landing/choose-image.png" class="img-fluid" alt="why choose image">
                </div> -->
            </div>
            <div class="col-lg-5">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="1s">
                    <div class="media d-flex">
                        <i class="flaticon-computer mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Who We Are: Transforming Visions into Reality</h3>
                            <p class="about-text">
                                At Brainex World, we're not just a software development company; 
                                we're a catalyst for innovation and growth. We specialize in a wide array 
                                of IT services and consulting, tailoring our solutions to match individual 
                                preferences. Our dedicated team of experts transforms ideas into reality, 
                                creating seamless experiences that exceed expectations.
                            </p>
                        </div>
                    </div>
                    <div class="media d-flex">
                        <i class="flaticon-computer mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Our Mission:</h3>
                            <p class="about-text">
                                Our mission goes beyond technical prowess. We are dedicated to providing 
                                free internships to talented individuals in Pakistan, irrespective of financial 
                                limitations. We believe that talent is boundless and should never be stifled 
                                by financial constraints. Through our internships, we nurture skills, cultivate 
                                potential, and prepare the next generation of IT leaders for successful
                                 careers in a rapidly evolving industry.
                            </p>
                        </div>
                    </div>
                    <!-- <div class="media d-flex">
                        <i class="flaticon-computer mr-3"></i>
                        <div class="media-body">
                            <h3 class="mt-0">Our Approach:</h3>
                            <p class="about-text">
                                We believe in the transformative power of technology. 
                                With a team of dedicated experts, we harness cutting-edge 
                                tools and creative thinking to craft solutions that make an impact. 
                                We understand that each project is unique, and we tailor our services 
                                to match your preferences, ensuring that your vision becomes a reality.
                            </p>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="shapes">
            <img src="assets/img/shape/1.png" alt="shape">
            <img src="assets/img/shape/2.png" alt="shape">
            <img src="assets/img/shape/3.png" alt="shape">
            <img src="assets/img/shape/4.png" alt="shape">
            <img src="assets/img/shape/5.png" alt="shape">
            <img src="assets/img/shape/6.png" alt="shape">
            <img src="assets/img/shape/7.png" alt="shape">
        </div>
    </div>
</div>