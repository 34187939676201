<div id="screenshots" class="screenshots-section ptb-100">
    <div class="container-fluid">
        <div class="section-title">
            <h2><i class="fa fa-users" aria-hidden="true"></i> Meet Our <strong class="team">Team</strong></h2>
            <span><small class="text-dark ms-5">Collaborative Minds, Creative Spirits</small></span>
            <p>
                Get to know the heart and soul of Brainex World – a team of collaborative minds and
                creative
                spirits dedicated to translating our vision into reality. Together, we strive to make a
                meaningful impact through innovation and technology.
            </p>
        </div>
        <!-- Team Section start -->
        <div class="container">
            <div class="box">
                <div class="top-bar"></div>
                <div class="top">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    <input type="checkbox" class="heart-btn" id="heart-btn-1">
                    <label class="heart" for="heart-btn-1"></label>
                </div>
                <div class="content">
                    <img src="/assets/img/team/saxzczxc.jpg" class="img-fluid" alt="">
                    <strong>TAYYAB RAZA</strong>
                    <strong>Managing Director</strong>
                    <p class="p-2 exp text-white">+4 years Startup Experience</p>
                </div>
                <div class="btn">
                    <a href="#"><i class="fa fa-clipboard" aria-hidden="true"></i>Assign</a>
                    <a href="#"><i class="fa fa-eye" aria-hidden="true"></i>View</a>
                </div>
            </div>
            <div class="box">
                <div class="top-bar"></div>
                <div class="top">
                    <div>
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                    </div>
                    <div>
                        <input type="checkbox" class="heart-btn" id="heart-btn-2">
                        <label class="heart" for="heart-btn-2"></label>
                    </div>
                </div>
                <div class="content">
                    <img src="/assets/img/team/yrt.jpg" class="img-fluid" alt="">
                    <strong>SABEEH UL HASSNAIN</strong>
                    <strong>Chief Executive Officer</strong>
                    <p class="p-2 exp text-white">+10 Years Business Development</p>
                </div>
                <div class="btn">
                    <a href="#"><i class="fa fa-clipboard" aria-hidden="true"></i>Assign</a>
                    <a href="#"><i class="fa fa-eye" aria-hidden="true"></i>View</a>
                </div>
            </div>
            <div class="box">
                <div class="top-bar"></div>
                <div class="top">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    <input type="checkbox" class="heart-btn" id="heart-btn-3">
                    <label class="heart" for="heart-btn-3"></label>
                </div>
                <div class="content">
                    <img src="/assets/img/team/zaka.png" class="img-fluid" alt="">
                    <strong>ZAKKAULLAH KHAN</strong>
                    <strong>3D Director</strong>
                    <p class="p-2 exp text-white">+4 years 3D Modeling Development</p>
                </div>
                <div class="btn">
                    <a href="#"><i class="fa fa-clipboard" aria-hidden="true"></i>Assign</a>
                    <a href="#"><i class="fa fa-eye" aria-hidden="true"></i>View</a>
                </div>
            </div>
            <div class="box">
                <div class="top-bar"></div>
                <div class="top">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    <input type="checkbox" class="heart-btn" id="heart-btn-4">
                    <label class="heart" for="heart-btn-4"></label>
                </div>
                <div class="content">
                    <img src="/assets/img/team/ayesha.jpg" class="img-fluid" alt="">
                    <strong>AYESHA NAZEER</strong>
                    <strong>HR Manager</strong>
                    <p class="p-2 exp text-white">+5 years Experience HRM</p>
                </div>
                <div class="btn">
                    <a href="#"><i class="fa fa-clipboard" aria-hidden="true"></i>Assign</a>
                    <a href="#"><i class="fa fa-eye" aria-hidden="true"></i>View</a>
                </div>
            </div>
            <div class="box">
                <div class="top-bar"></div>
                <div class="top">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    <input type="checkbox" class="heart-btn" id="heart-btn-4">
                    <label class="heart" for="heart-btn-4"></label>
                </div>
                <div class="content">
                    <img src="/assets/img/team/noshaba.jpg" class="img-fluid" alt="">
                    <strong>NOSHABA KHAN JADOON</strong>
                    <strong>Chief Technology Officer</strong>
                    <p class="p-2 exp text-white">+4 years Experience CTO</p>
                </div>
                <div class="btn">
                    <a href="#"><i class="fa fa-clipboard" aria-hidden="true"></i>Assign</a>
                    <a href="#"><i class="fa fa-eye" aria-hidden="true"></i>View</a>
                </div>
            </div>
            <div class="box">
                <div class="top-bar"></div>
                <div class="top">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                    <input type="checkbox" class="heart-btn" id="heart-btn-4">
                    <label class="heart" for="heart-btn-4"></label>
                </div>
                <div class="content">
                    <img src="/assets/img/team/uzair.jpg" class="img-fluid" alt="">
                    <strong>M. UZAIR ALI</strong>
                    <strong>Manager</strong>
                    <p class="p-2 exp text-white">+2 years Experience OM</p>
                </div>
                <div class="btn">
                    <a href="#"><i class="fa fa-clipboard" aria-hidden="true"></i>Assign</a>
                    <a href="#"><i class="fa fa-eye" aria-hidden="true"></i>View</a>
                </div>
            </div>
        </div>



        <!-- shapes start -->
        <div class="screenshot-shape">
            <img src="assets/img/shape/1.png" alt="shape">
            <img src="assets/img/shape/2.png" alt="shape">
            <img src="assets/img/shape/3.png" alt="shape">
            <img src="assets/img/shape/4.png" alt="shape">
            <img src="assets/img/shape/5.png" alt="shape">
            <img src="assets/img/shape/6.png" alt="shape">
        </div>
    </div>
</div>