<app-portfolio-banner></app-portfolio-banner>

<app-portfolio-about></app-portfolio-about>

<app-portfolio-services></app-portfolio-services>

<app-portfolio-software></app-portfolio-software>

<!-- <app-portfolio-showcase></app-portfolio-showcase> -->

<!-- <app-portfolio-partner></app-portfolio-partner> -->

<app-portfolio-testimonials></app-portfolio-testimonials>

<app-portfolio-contact></app-portfolio-contact>