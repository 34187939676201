<div id="home" class="main-banner banner-bg-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-8">
                        <div class="banner-text">
                            <h1>Empowering Dreams Through Technology</h1>
                            <h4 class="text-white">Bridging Talent and Opportunity for a Dynamic Future</h4>
                            <p> Explore a transformative journey with Brainex World, where we are dedicated to driving
                                innovation, nurturing skills, and shaping futures by leveraging the power of technology.
                            </p>
                            <div class="banner-btn">
                                <a href="https://wa.me/03436523451">Get Started</a>
                                <button class="banner-video"
                                    (click)="ngxSmartModalService.getModal('popupOne').open()"><i
                                        class="flaticon-play-button"></i> Watch Video</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <!-- <div class="banner-img text-end">
                            <img src="https://images.unsplash.com/photo-1629184510982-cf91280c1d53?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDh8fGxhcHRvcCUyMGdpcmx8ZW58MHwxfDB8fHww"
                                alt="iphone">
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="banner-shape">
        <img src="assets/img/shape/home-shape.png" alt="shape">
        <img src="assets/img/shape/home-shape-two.png" alt="shape">
        <img src="assets/img/shape/home-shape-four.png" alt="shape">
    </div>
</div>
<div class="video-popup">
    <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="assets/img/sass-landing/s-video.mp4"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
    </ngx-smart-modal>
</div>