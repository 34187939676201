import { Component, OnInit } from "@angular/core";
import Swal from "sweetalert2";
import { AuthService, User  } from "src/app/core/services/auth.service";

@Component({
    selector: "app-register",
    templateUrl: "./register.component.html",
    styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
    firstName = "";
    lastName = "";
    email = "";
    phoneNumber = "";
    username = "";
    password = "";

    constructor(private authService: AuthService) {}

    onSubmit(): void {
        const newUser: User = {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phoneNumber: this.phoneNumber,
            username: this.username,
            password: this.password,
        };

        this.authService.register(newUser).subscribe((success) => {
            if (success) {
                Swal.fire(
                    "Registration Successful",
                    "Welcome to our platform!",
                    "success"
                );
                // Redirect or perform any additional actions after successful registration
            } else {
                Swal.fire(
                    "Registration Failed",
                    "Username or Email already exists",
                    "error"
                );
            }
        });
    }

    ngOnInit(): void {}
}
