<div id="testimonials" class="testimonial-section mt-5">
    <div class="container-fluid">
        <div class="section-title">
            <span style="font-size: 1.8rem; font-weight: bold; color: rgba(26,95,122,1);">Testimonials</span>
            <h4>What People Say About Brainex World</h4>
            <p>
                At Brainex World, our commitment to excellence is reflected in the words of those
                we've had the privilege to work with. Here's what our clients and interns have to say
                about their experiences with us:
            </p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="testimonial-img">
                    <img src="assets/img/app-landing/map.png" alt="map image">
                    <div class="client-img">
                        <img src="assets/img/app-landing/testimonial/1.jpg" alt="client image">
                        <img src="assets/img/app-landing/testimonial/t2.png" alt="client image">
                        <img src="assets/img/app-landing/testimonial/t3.png" alt="client image">
                        <img src="assets/img/app-landing/testimonial/t4.png" alt="client image">
                        <img src="assets/img/app-landing/testimonial/t5.png" alt="client image">
                        <img src="assets/img/app-landing/testimonial/t6.jpg" alt="client image">
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="testimonial-slider">
                    <owl-carousel-o [options]="testimonialSlides">
                        <ng-template carouselSlide>
                            <div class="testimonial-item">
                                <img src="assets/img/app-landing/testimonial/favicon.png" alt="client image">
                                <div class="client-info">
                                    <h3>SH Designer</h3>
                                    <span>SHADERS</span>
                                </div>
                                <p>Brainex World transformed our business with their exceptional web development
                                    services. They took our vision and turned it into a reality that exceeded our
                                    expectations.
                                </p>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="testimonial-item">
                                <img src="assets/img/app-landing/testimonial/ayan.jpg" alt="client image">
                                <div class="client-info">
                                    <h3>AYAN HAIDER</h3>
                                    <span>WEB DEVELOPER</span>
                                </div>
                                <p>
                                    The free internship I received from Brainex World was a turning point in my career.
                                    Their dedication to nurturing talent is unmatched,
                                    and I'm now equipped with skills that
                                    have opened doors for me.
                                </p>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="testimonial-item">
                                <img src="assets/img/app-landing/testimonial/wahab.jpg" alt="client image">
                                <div class="client-info">
                                    <h3>ABDUL WAHAB JADOON</h3>
                                    <span>WEB DEVELOPER</span>
                                </div>
                                <p>
                                    I'm truly grateful for the opportunity Brainex World provided me through their free
                                    internships. They believed in my potential and helped me grow as a professional.
                                </p>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="testimonial-item">
                                <img src="assets/img/app-landing/testimonial/azka.jpg" alt="client image">
                                <div class="client-info">
                                    <h3>AZKA KHAN</h3>
                                    <span>UI/UX GRAPHIC DESIGNER</span>
                                </div>
                                <p>
                                    My graphic designing internship at Brainex World was a game-changer for me.
                                    The hands-on experience and mentorship I received allowed me to explore my
                                    creativity and enhance my skills. I'm now equipped to pursue a career in
                                    design with confidence.
                                </p>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="testimonial-item">
                                <img src="assets/img/app-landing/testimonial/laiba.jpg" alt="client image">
                                <div class="client-info">
                                    <h3>LAIBA RAJPOOT</h3>
                                    <span>WEB DEVELOPER</span>
                                </div>
                                <p>
                                    I learned more during my web development internship at Brainex World than I could
                                    have imagined. The projects were engaging, and the supportive team guided me through
                                    every step. This experience has given me a strong foundation in web development.
                                </p>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
        <div class="testimonial-shape">
            <img src="assets/img/shape/1.png" alt="shape">
            <img src="assets/img/shape/2.png" alt="shape">
            <img src="assets/img/shape/3.png" alt="shape">
            <img src="assets/img/shape/4.png" alt="shape">
            <img src="assets/img/shape/5.png" alt="shape">
        </div>
    </div>
</div><br><br>