import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServiceDetailsRoutingModule } from './service-details-routing.module';
import { WebDevelopmentComponent } from './web-development/web-development.component';
import { AppDevelopmentComponent } from './app-development/app-development.component';
import { PythonProgrammingComponent } from './python-programming/python-programming.component';
import { GraphicDesigningComponent } from './graphic-designing/graphic-designing.component';
import { AnimationModelingComponent } from './animation-modeling/animation-modeling.component';
import { VideoEditingComponent } from './video-editing/video-editing.component';
import { WordpressComponent } from './wordpress/wordpress.component';


@NgModule({
  declarations: [
    WebDevelopmentComponent,
    AppDevelopmentComponent,
    PythonProgrammingComponent,
    GraphicDesigningComponent,
    AnimationModelingComponent,
    VideoEditingComponent,
    WordpressComponent
  ],
  imports: [
    CommonModule,
    ServiceDetailsRoutingModule
  ]
})
export class ServiceDetailsModule { }
