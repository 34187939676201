<div id="testimonials" class="testimonial-style-three pb-100 mt-5">
    <div class="container">
        <div class="section-title">
            <h2>Peoples Quotes About Me</h2>
            <p>Insights and admiration from those who know me best, encapsulated in their quotes.</p>
        </div>
    </div>
    <div class="testimonial-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-sm-10 offset-sm-1">
                    <div class="testimonial-text-slider slider-for text-center">
                        <owl-carousel-o [options]="testimonialTextSlides">
                            <ng-template carouselSlide>
                                <div class="sin-testiText">
                                    <img src="assets/img/app-landing/testimonial/ayan.jpg" class="img-fluid"
                                        alt="client image">
                                    <h2>Syed Ayad Haider</h2>
                                    <span>Front-end Developer</span>
                                    <p>Brainex World has been my transformative learning journey as a front-end web
                                        developer. The depth of knowledge and mentorship provided by the team has been
                                        invaluable, propelling my skills and confidence to new heights. Grateful for the
                                        impactful learning experience!</p>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="sin-testiText">
                                    <img src="assets/img/app-landing/testimonial/azka.jpg" class="img-fluid"
                                        alt="client image">
                                    <h2>Azka Khan</h2>
                                    <span>UI/UX Designer</span>
                                    <p>
                                        Brainex World has been my creative sanctuary for honing Graphic UI/UX design
                                        skills. The collaborative environment and mentorship have shaped my artistic
                                        vision, allowing me to create visually compelling experiences that resonate with
                                        users. Grateful for the inspiring journey!
                                    </p>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="sin-testiText">
                                    <img src="assets/img/app-landing/testimonial/laiba.jpg" alt="client image">
                                    <h2>Laiba Rajpoot</h2>
                                    <span>Front-End Developer</span>
                                    <p>
                                        Brainex World has been a game-changer for my front-end development journey. The
                                        guidance and hands-on experience I gained here have been instrumental in shaping
                                        my skills and confidence. Proud to be part of a team that fosters innovation and
                                        growth!
                                    </p>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="sin-testiText">
                                    <img src="assets/img/app-landing/testimonial/wahab.jpg" alt="client image">
                                    <h2>Abdull Wahab Jadoon</h2>
                                    <span>Web Developer</span>
                                    <p>
                                        Brainex World has been my digital playground for web development excellence. The
                                        hands-on projects and collaborative learning environment have accelerated my
                                        growth, turning coding challenges into opportunities for innovation.
                                    </p>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="sin-testiText">
                                    <img src="assets/img/app-landing/testimonial/umer.jpg" alt="client image">
                                    <h2>Umar Ishfaq</h2>
                                    <span>Front-End Developer</span>
                                    <p>
                                        Brainex World has been the catalyst for my journey in front-end web development.
                                        The immersive learning experience and hands-on projects have sharpened my
                                        skills, allowing me to create visually appealing and responsive websites. Proud
                                        to be part of a community that fosters continuous growth and innovation.
                                    </p>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>