<div class="container-fluid main-bg">
          <div class="content">
                    <h2>3D-Modeling & Animation</h2>
                    <p class="sub-heading">
                              Home <i class="fa-solid fa-angles-right"></i> Services <i
                                        class="fa-solid fa-angles-right"></i> 3D-Modeling & Animation
                    </p>
                    <a href="https://wa.me/03436523451" class="btn">
                              Let's Start
                    </a>
          </div>
</div>

<div class="container">
          <div class="row">
                    <div class="col-lg-4 col-md-4 co-sm-12 mt-5">
                              <div id="left-side">
                                        <button class="btn form-control p-3 mt-3 active side-btn">Detailed
                                                  Precision</button>
                                        <button class="btn form-control p-3 mt-3 side-btn">Visual Storytelling</button>
                                        <button class="btn form-control p-3 mt-3 side-btn"> Innovation</button>
                                        <button class="btn form-control p-3 mt-3 side-btn">Collaboration</button>
                                        <div class="left-img mt-4">
                                                  <h6>Contact us now</h6>
                                                  <h3 class="mt-5">If need help!</h3>
                                                  <h2 class="mt-5">+92 3436523451</h2>
                                                  <p class="mt-5">or go to contact from</p>
                                                  <h4 class="mt-5"><em class="fw-bold">Company Email:</em>&nbsp;&nbsp;
                                                            brainexw@gmail.com</h4>
                                                  <h4 class="mt-5"><em class="fw-bold">HR Email:</em>&nbsp;&nbsp;
                                                            brainexworldhr@gmail.com</h4>
                                        </div>
                                        <div class="second-left-img mt-4">

                                        </div>
                              </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-12 mt-5">
                              <div id="right-side">
                                        <div class="righ-img mt-3">
                                                  <img src="../../../../assets/img/service/3d.jpg"
                                                            class="img-fluid" alt="">
                                        </div>
                                        <h2 class="right-title mt-4">3D Modeling and Architecture: Shaping Visions into
                                                  Reality</h2>
                                        <p>
                                                  Welcome to Brainex World's 3D Modeling and Architecture services,
                                                  where creativity takes form and ideas become tangible. Our team of
                                                  skilled architects and 3D artists specializes in turning concepts into
                                                  captivating visual experiences. From architectural designs to
                                                  intricate 3D models, we bring your imagination to life with precision
                                                  and artistry.
                                        </p>
                                        <h2 class="righ-sub-heading">Our Approach:</h2>
                                        <p>
                                                  At Brainex World, we understand that every design has a story to tell.
                                                  Our approach combines technical expertise with artistic vision to
                                                  create 3D models and architectural renderings that leave a lasting
                                                  impact. Here's how we turn your ideas into reality:
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">1:</strong>Detailed Precision
                                        </h3>
                                        <p class="strong-para">
                                                  Our architects meticulously craft every detail of your design,
                                                  ensuring accuracy and realism in every angle and element.
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">2:</strong>Visual Storytelling
                                        </h3>
                                        <p class="strong-para">
                                                  We don't just create models; we tell stories. Our 3D renderings
                                                  breathe life into your concepts, allowing stakeholders to envision the
                                                  final outcome.
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">3:</strong>Innovation</h3>
                                        <p class="strong-para">
                                                  We leverage cutting-edge software and technology to push the
                                                  boundaries of design, resulting in visually stunning and functionally
                                                  viable creations.
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">4:</strong>Collaboration</h3>
                                        <p class="strong-para">
                                                  Your vision is at the heart of our process. We collaborate closely
                                                  with you to understand your goals, preferences, and desired outcomes.
                                        </p>

                              </div>
                    </div>
          </div>
</div>
<!--Our 3D Modeling and Architecture Services:-->
<div class="container-fluid service-container mt-5">
          <h2 class="text-center title">Our 3D Modeling and Architecture Services:</h2>
          <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-12"></div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>Architectural Visualization</h6>
                                        <p class="mt-4">
                                                  Create realistic visualizations of architectural designs, interior
                                                  spaces, and exteriors.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>Product Prototyping</h6>
                                        <p class="mt-4">
                                                  Transform ideas into tangible product prototypes, allowing for
                                                  visualization and refinement before production.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>Urban Planning</h6>
                                        <p class="mt-4">
                                                  Develop comprehensive 3D models for urban planning, showcasing
                                                  infrastructure and development projects.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>Interior Design</h6>
                                        <p class="mt-4">
                                                  Craft immersive 3D representations of interior spaces, enabling
                                                  clients to visualize and refine design concepts.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>Virtual Tours</h6>
                                        <p class="mt-4">
                                                  Immerse stakeholders in virtual walkthroughs of architectural designs,
                                                  providing a realistic sense of scale and space.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-12"></div>

          </div>
</div>
<!-- WHY CHOOSE  -->
<div class="container-fluid mt-5 why-choose">
          <h2 class="text-center why-choose-title">Why Choose Brainex World <br> for 3D Modeling and Architecture?</h2>
          <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                              <img src="../../../../assets/img/service/3d-below.webp"
                                        class="img-fluid why-choose-img" alt="">
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                              <ul class="mt-5">
                                        <li class="mt-4">
                                                  <strong>Artistic Excellence:</strong>
                                                  <p>
                                                            Our team's artistic prowess ensures that every visual
                                                            created is not just accurate but also aesthetically pleasing
                                                            and emotionally impactful.
                                                  </p>
                                        </li>
                                        <li class="mt-4">
                                                  <strong>Technical Expertise:</strong>
                                                  <p>
                                                            With a deep understanding of architectural principles and 3D
                                                            software, we transform complex designs into photorealistic
                                                            renderings.
                                                  </p>
                                        </li>
                                        <li class="mt-4">
                                                  <strong>Client-Centric Focus:</strong>
                                                  <p>
                                                            Your satisfaction is our priority. We tailor our services to
                                                            meet your specific needs and deliver results that exceed
                                                            expectations.
                                                  </p>
                                        </li>
                                        <li class="mt-4">
                                                  <strong>Timely Delivery:</strong>
                                                  <p>
                                                            We understand the importance of project timelines. Our team
                                                            is committed to delivering high-quality visuals within your
                                                            specified timeframe.
                                                  </p>
                                        </li>
                              </ul>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12 mt-5">
                              <h3 class="fw-bold text-center mt-4">Elevate Your Vision:</h3>
                              <p class="text-center">
                                        Are you ready to bring your architectural dreams to life? Brainex World is here
                                        to help. Contact us today to discuss your project, and let's embark on a journey
                                        of transforming ideas into captivating 3D reality.
                              </p>
                    </div>
          </div>
</div>
<!-- CONTACT INFO  -->
<section class="container-fluid mt-5 contact-info">
          <h2>Contact Info</h2>

          <div class="contact-info">
                    <p><strong>Contact No:</strong> +92 3436523451</p>
                    <p><strong>Email:</strong> brainexw@gmail.com</p>
                    <p><strong>HR Email:</strong> brainexworldhr@gmail.com</p>
                    <p><strong>Address:</strong> Office 101, Emaan Palaza, Opposite Daewo Terminal Mandian Abbottabad
                              KPK Pakistan</p>
          </div>

          <div class="social-icons">
                    <a href="https://web.facebook.com/people/Brainex-world/100067619874325/" target="_blank"><i
                                        class="fa-brands fa-facebook"></i></a>
                    <a href="https://wa.me/03436523451" target="_blank"><i class="fa-brands fa-square-whatsapp"></i></a>
                    <a href="https://www.linkedin.com/company/brainex-world/" target="_blank"><i
                                        class="fa-brands fa-linkedin"></i></a>
                    <a href="#" target="_blank"><i class="fa-brands fa-google-plus"></i></a>

          </div>
</section>
<br><br>