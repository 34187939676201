<div id="services" class="service-style-two pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>We Providing Our Best Services</h2>
            <p>At Brainex World, we offer a range of IT services and consulting tailored to diverse needs.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <a routerLink="/services/web-development">
                        <img src="https://img.freepik.com/free-photo/programming-background-collage_23-2149901770.jpg?w=826&t=st=1699648601~exp=1699649201~hmac=831374e777e7ff64a741cd036177ea57b7f91fa3524f9c1692e925dfdf21ae79" alt="icon">
                        <h3>Web Development</h3>
                        <p>Craft responsive and engaging websites that leave a lasting impact.</p>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <a routerLink="/services/app-development">
                        <img src="../../../../../assets/img/service/app.jpeg" alt="icon">
                        <h3>App Development</h3>
                        <p>Create mobile applications that enrich user experiences and drive business growth.</p>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <a routerLink="/services/3d-modeling">
                        <img src="https://img.freepik.com/free-vector/realistic-composition-with-3d-printing-house-model-printed-helmet-vector-illustration_1284-30450.jpg?w=1060&t=st=1699648825~exp=1699649425~hmac=e7cd27d6f4b5812984932da7705214ef70cdd0b0973b1047f3983730a32c19f9" alt="icon">
                        <h3>3D Modeling and Architecture</h3>
                        <p>Transform concepts into captivating visual representations and architectural designs.</p>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <a routerLink="/services/python-programming">
                        <img src="https://img.freepik.com/free-vector/ui-ux-designers-isometric-composition-with-small-people-creating-custom-design-web-site-3d-vector-illustration_1284-68939.jpg?t=st=1699648903~exp=1699649503~hmac=589c4bd5047af8d1c58de95d83865723cd20bc3b872983e864e6358b6a41822a" alt="icon">
                        <h3>AI Python Programming</h3>
                        <p>Unlock the potential of AI with intelligent solutions developed in Python.</p>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <a routerLink="/services/wordpress">
                        <img src="https://img.freepik.com/free-psd/realistic-shiny-3d-round-button-with-wordpress-icon_125540-2913.jpg?w=826&t=st=1699649015~exp=1699649615~hmac=a8220c3b489ce12b34893cfc73fbab9eda35f80ca07f10906c00080727b6806c" alt="icon">
                        <h3>WordPress Customization</h3>
                        <p>Customize and optimize WordPress platforms for seamless online experiences.</p>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="service-item">
                    <a routerLink="/services/graphic-ui/us-desiging">
                        <img src="https://img.freepik.com/free-vector/gradient-colored-ui-ux-background_79603-1924.jpg?w=996&t=st=1699649082~exp=1699649682~hmac=20372bc8da9857f24a11f488d9fb1bdd74ce7236c27f8aae144cac669d88e06c" alt="icon">
                        <h3>Graphic Designing UI/UX</h3>
                        <p>Design visually appealing graphics and user interfaces that resonate.</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="service-shape">
        <img src="assets/img/map-four.png" alt="map shape">
        <img src="assets/img/sass-landing/shapes/3.png" alt="shape">
    </div>
</div>