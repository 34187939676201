<div id="about" class="features-style-two pb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="feature-image">
                    <img class="shadow-lg" src="https://images.unsplash.com/photo-1434626881859-194d67b2b86f?q=80&w=1748&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="laptop">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="feature-text">
                    <div class="section-title text-start">
                        <h2>Our Mission</h2>
                        <p>
                            Fostering talent, empowering ambitions globally.</p>
                    </div>
                    <p>
                        Our mission goes beyond technical prowess. We are dedicated to providing 
                        free internships to talented individuals in Pakistan, irrespective of financial 
                        limitations. We believe that talent is boundless and should never be stifled 
                        by financial constraints. Through our internships, we nurture skills, cultivate 
                        potential, and prepare the next generation of IT leaders for successful
                         careers in a rapidly evolving industry.
                    </p>
                    <div class="feature-btn">
                        <a href="https://wa.me/03436523451">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="featues-shape">
        <img src="assets/img/sass-landing/shapes/4.png" alt="shape">
    </div>
</div>