import { Component, OnInit } from "@angular/core";
import Swal from "sweetalert2";
import { AuthService } from "src/app/core/services/auth.service";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {

    ngOnInit(): void {}

    email = "";
    password = "";

    constructor(private authService: AuthService) {}

    onSubmit(): void {
        this.authService
            .login(this.email, this.password)
            .subscribe((success) => {
                if (success) {
                    Swal.fire("Login Successful", "Welcome back!", "success");
                    // Redirect or perform any additional actions after successful login
                } else {
                    Swal.fire(
                        "Login Failed",
                        "Invalid username or password",
                        "error"
                    );
                }
            });
    }
}
