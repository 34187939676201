<div id="home" class="main-banner banner-style-three banner-bg-five">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-text">
                            <h1>Ignite Your Tech Career in Pakistan</h1>
                            <p>
                                Ignite your tech career with Brainex World's internship program in Pakistan. Join us to unlock your potential, gain real-world experience, and shape a brighter future in the world of technology.
                            </p>
                            <div class="banner-btn">
                                <a routerLink="/agency-landing-two" class="active">Get Started</a>
                                <a routerLink="/agency-landing-two">Contact Us</a>     
                            </div>
                        </div>
                    </div>
                    <div class="banner-img-three">
                        
                        <!-- <img src="../assets/img/sass-landing/slider-1.webp" alt="consultant image"> -->
                    </div>
                </div>  
            </div>
        </div>
    </div>
</div>