<div id="pricing" class="pb-100">
    <div id="contact" class="contact-section contact-bg ptb-100" style="background-image: url('../../../../../assets/img/contact-bg.jpg');">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 offset-lg-5">
                    <div class="contact-form">
                        <h2>Let Me Know Your Project</h2>
                        <form id="contactForm">
                            <div class="row">
                                <div class="col-md-12 col-sm-6">
                                    <div class="form-group mb-3">
                                        <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-6">
                                    <div class="form-group mb-3">
                                        <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                    </div>
                                </div>
                                <div class="col-md-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group mb-3">
                                        <textarea name="message" class="message-field" id="message" cols="30" rows="5" placeholder="Message"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn contact-btn">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>