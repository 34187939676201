<div id="about" class="about-us-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-image text-center">
                    <img src="assets/img/videos/lower.jpg" class="img-fluid" alt="about image">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-text">
                    <div class="section-title text-start">
                        <span>About Us</span>
                        <h2>Tech-Powered Education: SetMind & Zentix VR</h2>
                    </div>
                    <p>Crafted with passion at the intersection of technology and education, our products, SetMind and Zentix VR, embody the essence of Brainex World's commitment to empower, innovate, and redefine the future of learning and immersive experiences.</p>
                    <ul>
                        <li><i class="flaticon-checked"></i>Cutting-Edge Technology</li>
                        <li><i class="flaticon-checked"></i>Passionate Craftsmanship</li>
                        <li><i class="flaticon-checked"></i>Future-Focused Learning</li>
                        <li><i class="flaticon-checked"></i>Innovation in Every Detail</li>
                        <li><i class="flaticon-checked"></i>Brainex World's Vision</li>
                    </ul>
                    <div class="about-btn">
                        <a routerLink="/agency-landing-one">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="about-shape">
        <img src="assets/img/map-two.png" alt="about shape">
    </div>
</div>