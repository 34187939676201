import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-python-programming',
  templateUrl: './python-programming.component.html',
  styleUrls: ['./python-programming.component.scss']
})
export class PythonProgrammingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
