<div id="services" class="feature-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Services</h2>
            <p>At Brainex World, we offer a range of IT services and consulting tailored to diverse needs:</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="feature-card wow fadeInUp" data-wow-duration="2s">
                    <i class="flaticon-layers"></i>
                    <h3>Web Development</h3>
                    <p>Craft responsive and engaging websites that leave a lasting impact</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="feature-card active wow fadeInUp" data-wow-duration="2s">
                    <i class="flaticon-clipboard"></i>
                    <h3>App Development</h3>
                    <p>Create mobile applications that enrich user experiences and drive business growth.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-sm-3 offset-md-3 offset-lg-0">
                <div class="feature-card wow fadeInUp" data-wow-duration="2s">
                    <i class="flaticon-credit-card"></i>
                    <h3>3D Modeling and Architecture</h3>
                    <p>Transform concepts into captivating visual representations and architectural designs.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-sm-3 offset-md-3 offset-lg-0">
                <div class="feature-card wow fadeInUp" data-wow-duration="2s">
                    <i class="flaticon-credit-card"></i>
                    <h3>AI Python Programming: </h3>
                    <p>Unlock the potential of AI with intelligent solutions developed in Python.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-sm-3 offset-md-3 offset-lg-0">
                <div class="feature-card wow fadeInUp" data-wow-duration="2s">
                    <i class="flaticon-credit-card"></i>
                    <h3>Graphic Designing UI/UX</h3>
                    <p>Design visually appealing graphics and user interfaces that resonate.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-sm-3 offset-md-3 offset-lg-0">
                <div class="feature-card wow fadeInUp" data-wow-duration="2s">
                    <i class="flaticon-credit-card"></i>
                    <h3>WordPress Customization</h3>
                    <p>Customize and optimize WordPress platforms for seamless online experiences.</p>
                </div>
            </div>
        </div>
    </div>
</div>