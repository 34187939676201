<div class="container-fluid main-bg">
          <div class="content">
                    <h2>App Development</h2>
                    <p class="sub-heading">
                              Home <i class="fa-solid fa-angles-right"></i> Services <i
                                        class="fa-solid fa-angles-right"></i> App Development
                    </p>
                    <a href="https://wa.me/03436523451" class="btn">
                              Let's Start
                    </a>
          </div>
</div>

<div class="container">
          <div class="row">
                    <div class="col-lg-4 col-md-4 co-sm-12 mt-5">
                              <div id="left-side">
                                        <button class="btn form-control p-3 mt-3 active side-btn">User-Centered
                                                  Design</button>
                                        <button class="btn form-control p-3 mt-3 side-btn">Cross-Platform
                                                  Compatibility</button>
                                        <button class="btn form-control p-3 mt-3 side-btn">Seamless
                                                  Functionality</button>
                                        <button class="btn form-control p-3 mt-3 side-btn">Performance
                                                  Optimization</button>
                                        <div class="left-img mt-4">
                                                  <h6>Contact us now</h6>
                                                  <h3 class="mt-5">If need help!</h3>
                                                  <h2 class="mt-5">+92 3436523451</h2>
                                                  <p class="mt-5">or go to contact from</p>
                                                  <h4 class="mt-5"><em class="fw-bold">Company Email:</em>&nbsp;&nbsp;
                                                            brainexw@gmail.com</h4>
                                                  <h4 class="mt-5"><em class="fw-bold">HR Email:</em>&nbsp;&nbsp;
                                                            brainexworldhr@gmail.com</h4>
                                        </div>
                                        <div class="second-left-img mt-4">

                                        </div>
                              </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-12 mt-5">
                              <div id="right-side">
                                        <div class="righ-img mt-3">
                                                  <img src="../../../../assets/img/service/service-below.jpg"
                                                            class="img-fluid" alt="">
                                        </div>
                                        <h2 class="right-title mt-4">App Development: Transforming Ideas into
                                                  Interactive Reality</h2>
                                        <p>
                                                  Welcome to Brainex World's App Development services, where innovation
                                                  meets functionality. We specialize in creating mobile applications
                                                  that not only meet your business needs but also engage and delight
                                                  your users. Our team of skilled developers, designers, and strategists
                                                  work collaboratively to bring your app idea to life, ensuring a
                                                  seamless and memorable user experience.
                                        </p>
                                        <h2 class="righ-sub-heading">Our Approach:</h2>
                                        <p>
                                                  At Brainex World, we understand that every app has a unique purpose
                                                  and audience. Our approach is rooted in creating user-centric,
                                                  feature-rich, and visually appealing applications that stand out in
                                                  the competitive app market. Here's how we make it happen:
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">1:</strong> User-Centered
                                                  Design</h3>
                                        <p class="strong-para">
                                                  Our design philosophy revolves around putting the user at the center.
                                                  We create intuitive interfaces that make navigation a breeze, ensuring
                                                  users stay engaged and delighted.
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">2:</strong>Cross-Platform
                                                  Compatibility</h3>
                                        <p class="strong-para">
                                                  With the ever-expanding range of devices, cross-platform development
                                                  is crucial. We develop apps that work flawlessly on iOS and Android,
                                                  catering to a wider user base.
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">3:</strong>Seamless
                                                  Functionality</h3>
                                        <p class="strong-para">
                                                  We prioritize functionality without sacrificing aesthetics. Our apps
                                                  not only look great but also perform efficiently, providing a seamless
                                                  experience to users.
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">4:</strong>Performance
                                                  Optimization</h3>
                                        <p class="strong-para">
                                                  Slow apps lead to frustration. Our developers optimize app performance
                                                  to ensure quick loading times and smooth interactions, even with
                                                  complex features.
                                        </p>

                              </div>
                    </div>
          </div>
</div>
<!-- Our Web Development Services: -->
<div class="container-fluid service-container mt-5">
          <h2 class="text-center title">Our App Development Services</h2>
          <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-12"></div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>Native Apps</h6>
                                        <p class="mt-4">
                                                  Harness the full potential of a specific platform with native app
                                                  development for iOS and Android.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>Hybrid Apps</h6>
                                        <p class="mt-4">
                                                  Enjoy cost-effective development and faster deployment using hybrid
                                                  frameworks like React Native and Flutter.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>E-Commerce Apps</h6>
                                        <p class="mt-4">
                                                  Elevate your online store with a user-friendly app that boosts sales
                                                  and customer loyalty.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>Custom Business Apps</h6>
                                        <p class="mt-4">
                                                  Tailored solutions to streamline internal processes, enhance
                                                  communication, and drive productivity.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>UI/UX Design</h6>
                                        <p class="mt-4">
                                                  Tailored solutions to streamline internal processes, enhance
                                                  communication, and drive productivity.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-12"></div>

          </div>
</div>
<!-- WHY CHOOSE  -->
<div class="container-fluid mt-5 why-choose">
          <h2 class="text-center why-choose-title">Why Choose Brainex World <br> for App Development?</h2>
          <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                              <img src="https://images.pexels.com/photos/1181263/pexels-photo-1181263.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                        class="img-fluid why-choose-img" alt="">
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                              <ul class="mt-5">
                                        <li class="mt-4">
                                                  <strong>Innovative Solutions:</strong>
                                                  <p>
                                                            We thrive on turning complex ideas into elegant and
                                                            practical app solutions that resonate with your target
                                                            audience.
                                                  </p>
                                        </li>
                                        <li class="mt-4">
                                                  <strong>Collaborative Approach: </strong>
                                                  <p>
                                                            Your insights are invaluable. We involve you in every step
                                                            of the development process to ensure your app aligns with
                                                            your vision.
                                                  </p>
                                        </li>
                                        <li class="mt-4">
                                                  <strong>Experienced Team:</strong>
                                                  <p>
                                                            Our team boasts a track record of successful app projects,
                                                            showcasing our technical expertise and commitment to
                                                            quality.
                                                  </p>
                                        </li>
                                        <li class="mt-4">
                                                  <strong>Timely Delivery:</strong>
                                                  <p>
                                                            We understand the importance of launching your app on time.
                                                            Our team works diligently to meet deadlines without
                                                            compromising quality.
                                                  </p>
                                        </li>
                              </ul>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12 mt-5">
                              <h3 class="fw-bold text-center mt-4">Let's Bring Your App Idea to Life:</h3>
                              <p class="text-center">
                                        Are you ready to take your business to the next level with a cutting-edge mobile
                                        application? Brainex World is your partner in turning your app concept into a
                                        reality. Contact us today to discuss your project and embark on a journey of
                                        innovation and success.
                              </p>
                    </div>
          </div>
</div>
<!-- CONTACT INFO  -->
<section class="container-fluid mt-5 contact-info">
          <h2>Contact Info</h2>

          <div class="contact-info">
                    <p><strong>Contact No:</strong> +92 3436523451</p>
                    <p><strong>Email:</strong> brainexw@gmail.com</p>
                    <p><strong>HR Email:</strong> brainexworldhr@gmail.com</p>
                    <p><strong>Address:</strong> Office 101, Emaan Palaza, Opposite Daewo Terminal Mandian Abbottabad
                              KPK Pakistan</p>
          </div>

          <div class="social-icons">
                    <a href="https://web.facebook.com/people/Brainex-world/100067619874325/" target="_blank"><i
                                        class="fa-brands fa-facebook"></i></a>
                    <a href="https://wa.me/03436523451" target="_blank"><i class="fa-brands fa-square-whatsapp"></i></a>
                    <a href="https://www.linkedin.com/company/brainex-world/" target="_blank"><i
                                        class="fa-brands fa-linkedin"></i></a>
                    <a href="#" target="_blank"><i class="fa-brands fa-google-plus"></i></a>

          </div>
</section>
<br><br>