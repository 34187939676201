<div id="apps" class="keep-update pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="keep-updating-text">
                    <div class="section-title text-start">
                        <h2>Join the Brainex World Community</h2>
                    </div>
                    <p>Join our vibrant Brainex World community, a thriving ecosystem of innovation and growth. Whether
                        you're seeking cutting-edge IT solutions, exploring exciting career opportunities, or looking to
                        collaborate with like-minded professionals, our community welcomes you. Dive into a world where
                        talent knows no boundaries, and ambition finds endless avenues. At Brainex World, we believe in
                        the power of collaboration, where every member contributes to a collective journey of excellence
                        in the dynamic realm of technology.</p>
                        <section>
                            <h2>Contact Us</h2>
                    
                            <div class="contact-info">
                                <p><strong>Contact No:</strong> +92 3436523451</p>
                                <p><strong>Email:</strong> brainexw@gmail.com</p>
                                <p><strong>Address:</strong> Office 101, Emaan Palaza, Opposite Daewo Terminal Mandian Abbottabad KPK Pakistan</p>
                            </div>
                    
                            <div class="social-icons">
                                <a href="https://web.facebook.com/people/Brainex-world/100067619874325/" target="_blank"><i class="fa-brands fa-facebook"></i></a>
                                <a href="https://wa.me/03436523451" target="_blank"><i class="fa-brands fa-square-whatsapp"></i></a>
                                <a href="https://www.linkedin.com/company/brainex-world/" target="_blank"><i class="fa-brands fa-linkedin"></i></a>
                                <a href="#" target="_blank"><i class="fa-brands fa-google-plus"></i></a>
                                <!-- Add more social media icons as needed -->
                            </div>
                        </section>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="keep-img float-end">
                    <img src="https://images.unsplash.com/photo-1557200134-90327ee9fafa?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="iphone">
                </div>
            </div>
        </div>
    </div>
    <div class="keep-shape">
        <img src="assets/img/sass-landing/shapes/5.png" alt="shape">
    </div>
</div>