<div id="case-study" class="case-study-section ptb-100">
    <div class="container-fluid">
        <div class="section-title">
            <span>Be a Part of Something Bigger</span>
            <h2>Join Us:</h2>
            <p>
                Are you ready to embark on a transformative journey that will shape your future? Apply now and be a part
                of the Brainex World Internship Program. Together, we're building a brighter, more inclusive IT
                landscape.
            </p>
        </div>
        <div class="case-study-slider">
            <div class="registration-form">
                <h2 class="title">Internship Application Form</h2>
                <span>
                    <button type="button" class="btn btn-success btn-sm form-btn mt-1 ms-5 print-button"
                        (click)="printForm()">Print Form</button>
                </span>
                <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()" class="mt-4">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="dateOfRegistration">Date of Registration</label>
                                <input type="date" id="dateOfRegistration" formControlName="dateOfRegistration">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="fullName">Full Name</label>
                                <input type="text" id="fullName" formControlName="fullName" required>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="fatherName">Father Name</label>
                                <input type="text" id="fatherName" formControlName="fatherName">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="placeOfBirth">Place of Birth</label>
                                <input type="text" id="placeOfBirth" formControlName="placeOfBirth">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="dateOfBirth">Date of Birth</label>
                                <input type="date" id="dateOfBirth" formControlName="dateOfBirth">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="domicile">Domicile</label>
                                <input type="text" id="domicile" formControlName="domicile">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input type="email" id="email" formControlName="email" required>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="phoneNumber">Phone Number</label>
                                <input type="tel" id="phoneNumber" formControlName="phoneNumber">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="gender">Gender</label>
                                <select id="gender" formControlName="gender">
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="nationalIdNo">National ID No</label>
                                <input type="text" id="nationalIdNo" formControlName="nationalIdNo">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="maritalStatus">Marital Status</label>
                                <select id="maritalStatus" formControlName="maritalStatus">
                                    <option value="single">Single</option>
                                    <option value="married">Married</option>
                                    <option value="divorced">Divorced</option>
                                    <option value="widowed">Widowed</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="address">Address</label>
                                <input type="text" id="address" formControlName="address">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="city">City</label>
                                <input type="text" id="city" formControlName="city">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="presentState">Present State</label>
                                <input type="text" id="presentState" formControlName="presentState">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label for="otherPhoneNumber">Other Phone Number</label>
                                <input type="tel" id="otherPhoneNumber" formControlName="otherPhoneNumber">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-4">
                            <button type="submit" class="form-btn mt-1 form-control">Apply</button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>

<div class="container-fluid">
    <h2 class="text-center fw-bold">Contact Info</h2>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 mt-3 p-5">
            <p><strong>Phone No: </strong>&nbsp; 03436523451</p>
            <p><strong>Phone No: </strong>&nbsp; 03239366163</p>
            <p><strong>HREmail </strong>&nbsp; brainexworldhr@gmail.com</p>
            <p><strong>CompanyEmail </strong>&nbsp; brainexw@gmail.com</p>
            <p><strong>Address: </strong>&nbsp; Brainex world, Office Number 101, Emaan Palaza, <br> Near Centeral Public School, Opposite Daewoo Terminal,  Mandian Abbottabad, KPK, Pakistan</p>

        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 mt-3 p-0">
            <img src="../../../../../assets/img/service/contact.jpg" class="img-fluid" alt="">
        </div>
    </div>
</div><br><br>