<div id="about" class="about-us-section about-style-two pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-image text-center">
                    <img src="https://img.freepik.com/free-photo/internship-management-temporary-position-concept_53876-139703.jpg?w=900&t=st=1699388095~exp=1699388695~hmac=0188cf441b006893edd15b0ae939c782cd67148f88a4300826d2b571b3fd82e2" alt="about image">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="about-text">
                    <div class="section-title text-start">
                        <h2>Ignite Your IT Career:</h2>
                        <h4 class="mt-2 fw-bold">Empowering Tech Talents in Pakistan</h4>
                    </div>
                    <p class="mt-5">
                        Welcome to the Brainex World Internship Program, where talent meets opportunity. Our commitment
                        to nurturing the next generation of IT professionals drives us to provide free internships to
                        qualified individuals in Pakistan. We believe that every passionate mind deserves a chance to
                        shine, regardless of financial constraints. Join us on a transformative journey that will shape
                        your career and unlock your potential.
                    </p>

                    <div class="about-btn mt-5">
                        <a routerLink="/agency-landing-two">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="about-shape">
        <img src="assets/img/map-two.png" alt="about shape">
    </div>
</div>


<!--LOWER SECTION WHY CHOSE BRAINEX WORLD INTERNSHIP-->
<div id="about" class="about-us-section about-style-two pt-75 pb-70">
    <h2 class="text-center fw-bold" style="color: rgba(26,95,122,1);">Why Choose Brainex World<br> Internships?</h2>

    <div class="container-fluid mt-5">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="about-text">
                    <div class="section-title text-start ">
                        <h4 class="mt-2 fw-bold" style="color: rgba(26,95,122,1);" >1: &nbsp; Hands-On Experience: </h4>
                    </div>
                    <p>
                        Gain real-world experience by working on live projects and collaborating with our seasoned
                        experts. Dive into the exciting realm of IT services and consulting, and develop skills that are
                        in high demand.
                    </p>
                    <h4 class="mt-2 fw-bold" style="color: rgba(26,95,122,1);" >2: &nbsp; Tailored Learning: </h4>
                    <p>
                        Our internship program is designed to match your interests and aspirations. Whether you're
                        inclined towards web development, app development, AI programming, or any other specialization,
                        we've got you covered.
                    </p>
                    <h4 class="mt-2 fw-bold" style="color: rgba(26,95,122,1);" >3: &nbsp; Mentorship: </h4>
                    <p>
                        Receive guidance and mentorship from industry professionals who are dedicated to your growth.
                        Learn from their insights, ask questions, and refine your skills under their expert supervision.
                    </p>
                    <h4 class="mt-2 fw-bold" style="color: rgba(26,95,122,1);" >4: &nbsp; Networking Opportunities: </h4>
                    <p>
                        Connect with like-minded interns, experienced professionals, and potential future employers.
                        Build a network that will be invaluable throughout your career journey.
                    </p>
                    <h4 class="mt-2 fw-bold" style="color: rgba(26,95,122,1);" >5: &nbsp; Inclusivity and Diversity: </h4>
                    <p>
                        At Brainex World, we celebrate diversity. Our internship program is open to individuals from all
                        backgrounds, promoting a collaborative and inclusive environment.
                    </p>
                    <div class="about-btn">
                        <a routerLink="/agency-landing-two">Get Started</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="about-image text-center">
                    <img src="https://img.freepik.com/free-photo/document-marketing-strategy-business-concept_53876-124278.jpg?w=900&t=st=1699388135~exp=1699388735~hmac=d49f920291b2e76746659b9423b7dd635b2ed5a6cd80d71c2c42fca55d175fd3" alt="about image">
                </div>
            </div>
        </div>
    </div>
</div>