import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebDevelopmentComponent } from './web-development/web-development.component';
import { AppDevelopmentComponent } from './app-development/app-development.component';
import { PythonProgrammingComponent } from './python-programming/python-programming.component';
import { AnimationModelingComponent } from './animation-modeling/animation-modeling.component';
import { VideoEditingComponent } from './video-editing/video-editing.component';
import { WordpressComponent } from './wordpress/wordpress.component';
import { GraphicDesigningComponent } from './graphic-designing/graphic-designing.component';

const routes: Routes = [
  {
    path: 'web-development',
    component: WebDevelopmentComponent
  },
  {
    path: 'app-development',
    component: AppDevelopmentComponent 
  },
  {
    path: 'python-programming',
    component: PythonProgrammingComponent
  },
  {
    path: '3d-modeling',
    component: AnimationModelingComponent
  },
  {
    path: 'video-editing',
    component: VideoEditingComponent
  },
  {
    path: 'wordpress',
    component: WordpressComponent
  },
  {
    path: 'graphic-ui/us-desiging',
    component: GraphicDesigningComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ServiceDetailsRoutingModule { }
