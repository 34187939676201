<div id="home" class="main-banner banner-style-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-text">
                            <h1>Get in Touch with Brainex World</h1>
                            <h6>Empowering Your Digital Dreams with Innovative IT Solutions</h6>
                            <p>Turning digital dreams into reality. Your go-to for web, app, Python, and 3D solutions. Let's redefine together.</p>
                            <div class="banner-btn">
                                <a href="https://wa.me/03436523451">Lets Talk!</a>
                                <a href="https://brainexw@gmail.com" class="cv-btn">Email Me</a>     
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 offset-lg-1">
                        <div class="banner-img-two">
                            <img src="https://assets-global.website-files.com/5f2fd61608fd62471b38ff8a/6308b4e7e911e73ce315cdad_CTA%20image.png" alt="iphone" class="img-fluid">
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
    <div class="banner-shape">
        <img src="assets/img/shape/home-shape.png" alt="shape">
        <img src="assets/img/shape/home-shape-two.png" alt="shape">
        <img src="assets/img/shape/home-shape-four.png" alt="shape">
    </div>
</div>