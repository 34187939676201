<div id="about" class="why-choose pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4">
                <div class="why-choose-img wow fadeInUp" data-wow-duration="1s">
                    <img src="https://images.unsplash.com/photo-1553861542-15283bc1bcd2?q=80&w=1827&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt="why choose image">
                </div>
            </div>
            <div class="col-lg-7 offset-lg-1">
                <div class="why-choose-text wow fadeInUp" data-wow-duration="2s">
                    <div class="section-title">
                        <span>About Us</span>
                        <h2>Empowering Dreams, Fostering Innovation</h2>
                        <p>Welcome to Brainex World, a dynamic IT services and consulting company with a mission to
                            empower individuals, businesses, and communities through technology. Founded with a vision
                            to bridge the gap between talent and opportunity, Brainex World is committed to driving
                            innovation, nurturing skills, and shaping futures.
                        </p>
                    </div>
                    <div class="mb-5">
                        <h2 class="our-stroy">Our Story</h2>
                        <p>
                            Brainex World was born from a passion for technology and a desire to make a meaningful
                            impact. With a foundation in IT services and consulting, we recognized the immense potential
                            that lies within talented individuals, often hindered by financial constraints. Our journey
                            began with a mission to provide free internships, particularly for qualified individuals in
                            Pakistan, enabling them to pursue their aspirations and build successful careers.
                        </p>

                        <h2 class="our-stroy">Our Mission</h2>
                        <p>
                            Our mission is clear—to empower individuals with talent and ambition, regardless of their
                            financial background. Through free internships, we nurture skills, provide hands-on
                            experience, and prepare individuals for successful careers in the IT industry.
                        </p>
                    </div>
                    <div class="banner-btn">
                        <a href="https://wa.me/03436523451">Get Started</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>