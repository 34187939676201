<div id="services" class="service-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Development Services:</span>
            <h2>Turning Concepts into Reality: Explore Innovative Solutions with Us.</h2>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div routerLink="/web-development" class="service-card service-card-one wow fadeInUp" data-wow-duration="1s">
                    <i class="flaticon-pie-chart"></i>
                    <h3 style="color: #187b9a;">Web Development</h3>
                    <p style="font-size: 0.8rem;">Craft stunning and responsive websites that engage and captivate your audience.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div routerLink="/app-development" class="service-card service-card-two wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                    <i class="flaticon-rocket"></i>
                    <h3 style="color: #187b9a;">App Development</h3>
                    <p style="font-size: 0.8rem;">Turn your vision into user-friendly, feature-rich mobile applications that stand out in the digital landscape.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div routerLink="/3d-modeling" class="service-card service-card-three wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                    <i class="flaticon-folder"></i>
                    <h3 style="color: #187b9a;">3D Modeling and Architecture</h3>
                    <p style="font-size: 0.8rem;">Create immersive 3D models and architectural designs that bring your concepts to life.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div routerLink="/python-programming" class="service-card service-card-four wow fadeInUp" data-wow-duration="1s" data-wow-delay=".4s">
                    <i class="flaticon-relationship"></i>
                    <h3 style="color: #187b9a;">AI Python Programming</h3>
                    <p style="font-size: 0.8rem;">Harness the power of AI with our Python programming expertise, making your software smarter and more efficient.</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div routerLink="/video-editing" class="service-card service-card-five wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
                    <i class="flaticon-cloud-computing"></i>
                    <h3 style="color: #187b9a;">Video Editing</h3>
                    <p style="font-size: 0.8rem;">From concept to final cut, we deliver compelling video content that tells your story.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div routerLink="/graphic-ui/us-desiging" class="service-card service-card-six wow fadeInUp" data-wow-duration="1s" data-wow-delay=".6s">
                    <i class="flaticon-verified"></i>
                    <h3 style="color: #187b9a;">Graphic Designing UI/UX</h3>
                    <p style="font-size: 0.8rem;">Design visually appealing graphics and intuitive user interfaces that leave a lasting impression.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div routerLink="wordpress" class="service-card service-card-six wow fadeInUp" data-wow-duration="1s" data-wow-delay=".6s">
                    <i class="flaticon-verified"></i>
                    <h3 style="color: #187b9a;">WordPress</h3>
                    <p style="font-size: 0.8rem;">Customize and optimize your WordPress platform for seamless performance and functionality.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="service-card service-card-six wow fadeInUp" data-wow-duration="1s" data-wow-delay=".6s">
                    <i class="flaticon-verified"></i>
                    <h3 style="color: #187b9a;">Freelancing</h3>
                    <p style="font-size: 0.8rem;">oin our team of skilled professionals and embrace flexible, remote opportunities that align with your expertise.</p>
                </div>
            </div>

        </div>
    </div>
</div>