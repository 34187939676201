<div class="standard-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="standard-text">
                    <div class="section-title text-start">
                        <span style="font-size: 1.8rem; font-weight: 700; color: rgba(26,95,122,1);">Why Choose Brainex World:</span>
                    </div>
                    <ul>
                        <li>
                            <h6 style="color: rgba(26,95,122,1); font-weight: bold;">Empowerment</h6>
                            <p>
                                Our commitment to providing free internships demonstrates our dedication to empowering 
                                individuals and communities. We believe that empowerment through education and
                                experience is the key to unlocking human potential.
                            </p>
                        </li>
                        <li>
                            <h6 style="color: rgba(26,95,122,1); font-weight: bold;">Collaboration</h6>
                            <p>
                                Join a community that values collaboration and knowledge-sharing. 
                                Whether you're a client or an intern, you become a part of a network 
                                that nurtures growth and fosters meaningful connections.
                            </p>
                        </li>
                    </ul>
                    <div class="standard-btn">
                        <a routerLink="/">Read More....</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="standard-img">
                    <img src="assets/img/sass-landing/about1.jpg" alt="laptop">
                </div>
            </div>
        </div>
        <div class="standard-shape mb-3">
            <img src="assets/img/sass-landing/about3.jpeg" alt="shape">
        </div>
    </div>
</div>