<app-agency-one-banner></app-agency-one-banner>

<app-agency-one-about></app-agency-one-about>

<app-agency-one-services></app-agency-one-services>

<app-agency-one-work-process></app-agency-one-work-process>

<!-- <app-agency-one-case-study></app-agency-one-case-study> -->

<!-- <app-agency-one-testimonials></app-agency-one-testimonials> -->

<!-- <app-agency-one-pricing></app-agency-one-pricing> -->

<app-agency-one-team></app-agency-one-team>