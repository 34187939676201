<div id="home" class="main-banner main-banner-two banner-bg-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text justify-content-center">
                    <h1 class="fw-bold">Unleashing Digital Excellence Together.</h1>
                    <p  class="fw-bold">Elevate your digital presence with Brainex World - where innovation meets tailored solutions for web, app, AI, 3D modeling, video editing, and graphic design.</p>
                    <div class="banner-btn">
                        <a href="https://wa.me/03436523451">Get Started</a>
                        <button class="banner-video" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="flaticon-play-button"></i> Watch Video</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="banner-image-three">
        <!-- <img src="assets/img/sass-landing/slider-3.webp" alt="laptop"> -->
        <img src="assets/img/shape/home-shape-three.png" alt="shape">
    </div>
    <div class="banner-shape-two">
        <img src="assets/img/sass-landing/shapes/1.png" alt="shape">
        <img src="assets/img/sass-landing/shapes/1.png" alt="shape">
    </div>
</div>
<div class="video-popup">
    <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="assets/img/sass-landing/s-video.mp4"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal>
</div>