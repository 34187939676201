<div class="container-fluid main-bg">
          <div class="content">
                    <h2>WordPress</h2>
                    <p class="sub-heading">
                              Home <i class="fa-solid fa-angles-right"></i> Services <i
                                        class="fa-solid fa-angles-right"></i> WordPress
                    </p>
                    <a href="https://wa.me/03436523451" class="btn">
                              Let's Start
                    </a>
          </div>
</div>

<div class="container">
          <div class="row">
                    <div class="col-lg-4 col-md-4 co-sm-12 mt-5">
                              <div id="left-side">
                                        <button class="btn form-control p-3 mt-3 active side-btn">Tailored
                                                  Themes</button>
                                        <button class="btn form-control p-3 mt-3 side-btn">Plugin Customization</button>
                                        <button class="btn form-control p-3 mt-3 side-btn">Responsive Design</button>
                                        <button class="btn form-control p-3 mt-3 side-btn">Search Engine Optimization
                                                  (SEO): </button>
                                        <div class="left-img mt-4">
                                                  <h6>Contact us now</h6>
                                                  <h3 class="mt-5">If need help!</h3>
                                                  <h2 class="mt-5">+92 3436523451</h2>
                                                  <p class="mt-5">or go to contact from</p>
                                                  <h4 class="mt-5"><em class="fw-bold">Company Email:</em>&nbsp;&nbsp;
                                                            brainexw@gmail.com</h4>
                                                  <h4 class="mt-5"><em class="fw-bold">HR Email:</em>&nbsp;&nbsp;
                                                            brainexworldhr@gmail.com</h4>
                                        </div>
                                        <div class="second-left-img mt-4">

                                        </div>
                              </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-12 mt-5">
                              <div id="right-side">
                                        <div class="righ-img mt-3">
                                                  <img src="../../../../assets/img/service/wordpress.jpg"
                                                            class="img-fluid" alt="">
                                        </div>
                                        <h2 class="right-title mt-4">WordPress Customization: Empowering Your Online
                                                  Presence</h2>
                                        <p>
                                                  Welcome to Brainex World's WordPress Customization services, where
                                                  versatility meets innovation. Our team of skilled developers and
                                                  designers specializes in optimizing, customizing, and enhancing your
                                                  WordPress platform to create a seamless and captivating online
                                                  experience. From stunning websites to user-friendly interfaces, we
                                                  transform WordPress into a powerful tool that aligns with your brand
                                                  and goals.
                                        </p>
                                        <h2 class="righ-sub-heading">Our Approach:</h2>
                                        <p>
                                                  At Brainex World, we understand that your website is your digital
                                                  storefront. Our approach combines technical expertise with creative
                                                  customization to tailor your WordPress platform to your unique needs.
                                                  Here's how we turn WordPress into your strategic advantage:
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">1:</strong>Tailored Themes
                                        </h3>
                                        <p class="strong-para">
                                                  We create custom themes that reflect your brand identity and provide a
                                                  consistent and engaging user experience.
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">2:</strong>Plugin
                                                  Customization</h3>
                                        <p class="strong-para">
                                                  Our developers customize plugins to add features, enhance
                                                  functionality, and improve user engagement.
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">3:</strong>Responsive Design
                                        </h3>
                                        <p class="strong-para">
                                                  We ensure your website looks and functions flawlessly across all
                                                  devices, providing a consistent experience for users.
                                        </p>
                                        <h3 class="strong-heading"><strong class="strong">4:</strong>Search Engine
                                                  Optimization (SEO)</h3>
                                        <p class="strong-para">
                                                  Our experts optimize your WordPress site for search engines, boosting
                                                  your online visibility and driving organic traffic.
                                        </p>

                              </div>
                    </div>
          </div>
</div>
<!-- Our WordPress Customization Services: -->
<div class="container-fluid service-container mt-5">
          <h2 class="text-center title">Our WordPress Customization Services</h2>
          <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-12"></div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>Custom Theme Development</h6>
                                        <p class="mt-4">
                                                  Integrate and customize plugins that enhance your website's
                                                  functionality and user experience.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>Plugin Integration</h6>
                                        <p class="mt-4">
                                                  Integrate and customize plugins that enhance your website's
                                                  functionality and user experience.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>E-Commerce Solutions</h6>
                                        <p class="mt-4">
                                                  Set up and customize e-commerce platforms, turning your website into a
                                                  powerful online store.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>Content Management</h6>
                                        <p class="mt-4">
                                                  Develop intuitive content management systems that empower you to
                                                  update and manage your website easily.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
                              <div class="box">
                                        <h6>Custom Web Applications</h6>
                                        <p class="mt-4">
                                                  Tailored solutions for unique business needs, enhancing efficiency and
                                                  productivity.
                                        </p>
                              </div>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-12"></div>

          </div>
</div>
<!-- WHY CHOOSE  -->
<div class="container-fluid mt-5 why-choose">
          <h2 class="text-center why-choose-title">Why Choose Brainex World <br> for WordPress Customization?</h2>
          <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                              <img src="https://img.freepik.com/free-vector/illustration-data-analysis-graph_53876-18131.jpg?w=996&t=st=1701078109~exp=1701078709~hmac=b002a0d9a04f98eb7d84fb5f3c4130c63fa776b9ff32813bf59246ab6c94745f"
                                        class="img-fluid why-choose-img" alt="">
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                              <ul class="mt-5">
                                        <li class="mt-4">
                                                  <strong>Technical Expertise:</strong>
                                                  <p>
                                                            Our developers possess in-depth knowledge of WordPress,
                                                            ensuring seamless customization and optimization.
                                                  </p>
                                        </li>
                                        <li class="mt-4">
                                                  <strong>Creative Flexibility:</strong>
                                                  <p>
                                                            We tailor our solutions to match your brand's personality,
                                                            values, and design preferences.
                                                  </p>
                                        </li>
                                        <li class="mt-4">
                                                  <strong>Collaborative Approach:</strong>
                                                  <p>
                                                            We involve you in the process, ensuring that your
                                                            WordPress platform aligns with your vision and goals.
                                                  </p>
                                        </li>
                                        <li class="mt-4">
                                                  <strong>Results-Driven:</strong>
                                                  <p>
                                                            Our WordPress customization is focused on delivering
                                                            tangible results, from improved user engagement to higher
                                                            conversion rates.
                                                  </p>
                                        </li>
                              </ul>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12 mt-5">
                              <h3 class="fw-bold text-center mt-4">Elevate Your WordPress Experience:</h3>
                              <p class="text-center">
                                        Are you ready to transform your WordPress platform into a captivating online
                                        hub? Brainex World is your partner in crafting exceptional web experiences. <br>
                                        Contact us today to discuss your WordPress customization project and embark on a
                                        journey of online excellence.
                              </p>
                    </div>
          </div>
</div>
<!-- CONTACT INFO  -->
<section class="container-fluid mt-5 contact-info">
          <h2>Contact Info</h2>

          <div class="contact-info">
                    <p><strong>Contact No:</strong> +92 3436523451</p>
                    <p><strong>Email:</strong> brainexw@gmail.com</p>
                    <p><strong>HR Email:</strong> brainexworldhr@gmail.com</p>
                    <p><strong>Address:</strong> Office 101, Emaan Palaza, Opposite Daewo Terminal Mandian Abbottabad
                              KPK Pakistan</p>
          </div>

          <div class="social-icons">
                    <a href="https://web.facebook.com/people/Brainex-world/100067619874325/" target="_blank"><i
                                        class="fa-brands fa-facebook"></i></a>
                    <a href="https://wa.me/03436523451" target="_blank"><i class="fa-brands fa-square-whatsapp"></i></a>
                    <a href="https://www.linkedin.com/company/brainex-world/" target="_blank"><i
                                        class="fa-brands fa-linkedin"></i></a>
                    <a href="#" target="_blank"><i class="fa-brands fa-google-plus"></i></a>

          </div>
</section>
<br><br>