<div id="about" class="about-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-4 p-0">
                <div class="about-img wow fadeInUp" data-wow-duration="1s">
                    <img src="https://t3.ftcdn.net/jpg/03/32/97/92/360_F_332979284_D1LSstKEOoI7UqF5Mr2wwyy4W5eP0mz3.jpg" alt="about image" class="img-fluid shadow-lg">
                    <div class="about-img-text">
                        <p class="fw-bold">Brainex World Pvt Ltd</p>
                        <ul>
                            <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-behance"></i></a></li>
                            <li><a href="#" target="_blank"><i class="flaticon-globe"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="about-text wow fadeInUp" data-wow-duration="1s">
                    <h2>Unveiling Brainex World</h2>
                    <p>
                        Embark on a journey with Brainex World, your gateway to a world of innovation and technology. We
                        specialize in web and app development, Python programming, and 3D modeling. Committed to turning
                        your visions into reality, we invite you to explore a digital realm where creativity meets
                        functionality. Let's craft a future of possibilities together.
                    </p>
                    <ul>
                        <li><i class="flaticon-email"></i>brainexw@gmail.com</li>
                        <li><i class="flaticon-maps-and-flags"></i>Office 101,Eman Palaza</li>
                        <li><i class="flaticon-call"></i>+92 343 6523451</li>
                        <li><i class="flaticon-globe"></i>www.brainexworld.com</li>
                        <li><i class="flaticon-flag"></i>Abbottabad Pakistan</li>
                        <li><i class="flaticon-work-from-home"></i>Exploring Brainex World</li>
                    </ul>
                    <div class="about-btn">
                        <a routerLink="/portfolio-landing">Lets Talk!</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="about-shape">
        <img src="assets/img/map-two.png" alt="map">
    </div>
</div>